export abstract class Constants {
    private constructor() {
        /* noop */
    }

    static LoadBalancer = class {
        static KEY_STICKYNIESS_COOKIE_NAME = 'th-sticky';
    };

    static Track = class {
        static KEY_LANADING_PAGE_TRACKING_COOKIE_NAME = 'th-lp-enter';

        static KEY_LANADING_PAGE_SKIP_ONBOARDING = 'th-lp-skip-onboarding';

        static KEY_SESSION_COOKIE_NAME = 'th-session';

        static KEY_DEVICE_COOKIE_NAME = 'th-device';
    };

    static Auth = class {
        static KEY_COOKIE_NAME = 'th-auth';

        static KEY_REFRESH_TOKEN = 'th_refresh_token';

        static TIME_FOR_OTP_RESEND = 60000;

        static TIME_TO_REFRESH_TOKEN_SCHEDULE = 300000;
    };

    static Links = class {
        static LINK_ARTISTS = '/artists';

        static LINK_MARKETPLACE = '/marketplace';

        static LINK_ABOUT = '/about';

        static LINK_AUTHENTICATION = '/authentication';

        static LINK_CONTACT = '/contact-us';

        static LINK_HELP = '/help';

        static LINK_TOS = '/terms-of-service';

        static LINK_PRIVACY = '/privacy-policy';

        static LINK_BLOG = '/blog';

        static LINK_SETTINGS = '/account/profile';

        static LINK_COOKIES = '/cookies';

        static LINK_ACCOUNT = '/account';

        static LINK_ITEMS_ACTIVE_BIDS = '/account/active-bids';

        static LINK_ITEMS_BIDS_HISTORY = '/account/bids-history';

        static LINK_ITEMS_LIKED = '/account/favorites';

        static LINK_RECENTLY_VIEWED = '/account/recently-viewed';

        static LINK_ITEMS_OWNED = '/account/owned-items';

        static LINK_OFFERS = '/account/offers';

        static LINK_ITEMS_IN_TRANSACTION = '/account/in-transaction';

        static LINK_ITEMS_OFFERS = '/items/offers';

        static LINK_ITEMS_SELLING = '/items/selling';

        static LINK_SETTINGS_PROFILE_EDIT = '/account/profile';

        static LINK_SETTINGS_ACCOUNT_EDIT = '/account/profile';

        static LINK_SETTINGS_ADDRESSES = '/account/profile';

        static LINK_SETTINGS_INVOICES = '/account/invoices';

        static LINK_SETTINGS_PAYMENT_METHOD = '/account/payments';

        static LINK_SETTINGS_PAYOUT = '/account/payments';

        static LINK_SETTINGS_BANK_ACCOUNT = '/account/payments';

        static LINK_SETTINGS_CREDIT_LIMIT = '/account/credit-limit';

        static LINK_SETTINGS_NOTIFICATIONS = '/account/notifications';

        static LINK_SETTINGS_SECURITY = '/account/security';

        static LINK_SETTINGS_TAX = '/account/tax';

        static LINK_SETTINGS_REDEEM = '/account/redeem';

        static LINK_SETTINGS_NEWS = '/account/news';
    };

    static Support = class {
        static RESALE_GUIDE = 'https://support.therealest.com/hc/en-us/sections/31113134106388-Resales';

        static RESALE_SET_MIN = 'https://support.therealest.com/hc/en-us/articles/31119225965204-How-do-I-change-my-item-s-minimum-threshold-for-resale-offers';

        static BIDDING_PROCESS = 'https://support.therealest.com/hc/en-us/articles/21154357714708-How-does-the-bidding-process-work';
    };
}
