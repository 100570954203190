/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * TheRealest API Documentation
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from 'url';
import { Configuration } from './configuration';

let fetchImplementation: typeof fetch;

if (process.env.NEXT_RUNTIME === 'nodejs') {
    // Server-side
    fetchImplementation = require('undici').fetch;
} else {
    // Client-side
    fetchImplementation = fetch; // Use the browser's native fetch
}

const BASE_PATH = '/'.replace(/\/+$/, '');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ',',
    ssv: ' ',
    tsv: '\t',
    pipes: '|',
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration!: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = fetchImplementation) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name!: 'RequiredError'
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AddressDto
 */
export interface AddressDto {
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    full_name: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    line_1: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    line_2?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    postal_code: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    mode: AddressDto.ModeEnum;
}

/**
 * @export
 * @namespace AddressDto
 */
export namespace AddressDto {
    /**
     * @export
     * @enum {string}
     */
    export enum ModeEnum {
        None = <any> 'none',
        Shipping = <any> 'shipping',
        Billing = <any> 'billing',
        Both = <any> 'both'
    }
}
/**
 * 
 * @export
 * @interface Auction
 */
export interface Auction {
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    updated_at: string;
    /**
     * 
     * @type {number}
     * @memberof Auction
     */
    amount_of_bids: number;
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    start_date: string;
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    end_date: string;
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    extended_bidding_end_date: string;
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    workflow?: string;
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    sale_type: Auction.SaleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    type: Auction.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    item_assignment: Auction.ItemAssignmentEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Auction
     */
    customer_item_assignment: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Auction
     */
    online_payment_accepted: boolean;
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    buy_now_amount?: string;
    /**
     * 
     * @type {AuctionBuyNowPromotion}
     * @memberof Auction
     */
    promotion?: AuctionBuyNowPromotion;
    /**
     * 
     * @type {boolean}
     * @memberof Auction
     */
    is_promoted: boolean;
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    promoted_amount?: string;
    /**
     * 
     * @type {number}
     * @memberof Auction
     */
    item_amount_available?: number;
    /**
     * 
     * @type {number}
     * @memberof Auction
     */
    purchase_qty_allowed?: number;
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    expected_delivery_date?: string;
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    status: Auction.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    fulfillment: Auction.FulfillmentEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof Auction
     */
    pickup_options?: Array<string>;
    /**
     * 
     * @type {Item}
     * @memberof Auction
     */
    item?: Item;
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    current_bid_id?: string;
    /**
     * 
     * @type {AuctionBid}
     * @memberof Auction
     */
    current_bid?: AuctionBid;
    /**
     * 
     * @type {Array<AuctionBid>}
     * @memberof Auction
     */
    bids: Array<AuctionBid>;
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    min_next_bid: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Auction
     */
    min_next_bids: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    current_amount: string;
    /**
     * 
     * @type {string}
     * @memberof Auction
     */
    blind_bid_end_date: string;
    /**
     * 
     * @type {boolean}
     * @memberof Auction
     */
    has_sold: boolean;
}

/**
 * @export
 * @namespace Auction
 */
export namespace Auction {
    /**
     * @export
     * @enum {string}
     */
    export enum SaleTypeEnum {
        Primary = <any> 'primary',
        Secondary = <any> 'secondary'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Auction = <any> 'auction',
        BuyNow = <any> 'buy_now'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum ItemAssignmentEnum {
        Virtual = <any> 'virtual',
        Final = <any> 'final'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        NotStarted = <any> 'not_started',
        Open = <any> 'open',
        Extended = <any> 'extended',
        Blind = <any> 'blind',
        Closed = <any> 'closed',
        Done = <any> 'done'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum FulfillmentEnum {
        Ship = <any> 'ship',
        External = <any> 'external',
        Pickup = <any> 'pickup',
        PickupShip = <any> 'pickup_ship'
    }
}
/**
 * 
 * @export
 * @interface AuctionBid
 */
export interface AuctionBid {
    /**
     * 
     * @type {string}
     * @memberof AuctionBid
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AuctionBid
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof AuctionBid
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof AuctionBid
     */
    amount: string;
    /**
     * The max amount bid. Only set if the current users bid
     * @type {string}
     * @memberof AuctionBid
     */
    max_amount?: string;
    /**
     * 
     * @type {string}
     * @memberof AuctionBid
     */
    placed_at: string;
    /**
     * 
     * @type {string}
     * @memberof AuctionBid
     */
    user_id: string;
    /**
     * 
     * @type {User}
     * @memberof AuctionBid
     */
    user: User;
    /**
     * 
     * @type {string}
     * @memberof AuctionBid
     */
    auction_id: string;
    /**
     * 
     * @type {Auction}
     * @memberof AuctionBid
     */
    auction?: Auction;
    /**
     * 
     * @type {string}
     * @memberof AuctionBid
     */
    payment_method_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AuctionBid
     */
    payment_method?: AuctionBid.PaymentMethodEnum;
}

/**
 * @export
 * @namespace AuctionBid
 */
export namespace AuctionBid {
    /**
     * @export
     * @enum {string}
     */
    export enum PaymentMethodEnum {
        Card = <any> 'user_card',
        BankAccount = <any> 'user_bank_account'
    }
}
/**
 * 
 * @export
 * @interface AuctionBuyEstimate
 */
export interface AuctionBuyEstimate {
    /**
     * 
     * @type {string}
     * @memberof AuctionBuyEstimate
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AuctionBuyEstimate
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof AuctionBuyEstimate
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof AuctionBuyEstimate
     */
    item_amount: string;
    /**
     * 
     * @type {string}
     * @memberof AuctionBuyEstimate
     */
    shipping_amount: string;
    /**
     * 
     * @type {string}
     * @memberof AuctionBuyEstimate
     */
    tax_amount: string;
    /**
     * 
     * @type {string}
     * @memberof AuctionBuyEstimate
     */
    fee_amount: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuctionBuyEstimate
     */
    promotion_applied: boolean;
    /**
     * 
     * @type {number}
     * @memberof AuctionBuyEstimate
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof AuctionBuyEstimate
     */
    pickup_option?: string;
    /**
     * 
     * @type {string}
     * @memberof AuctionBuyEstimate
     */
    tax_calculation_id: string;
    /**
     * 
     * @type {string}
     * @memberof AuctionBuyEstimate
     */
    expires_at: string;
    /**
     * 
     * @type {CouponUsageCalculationDto}
     * @memberof AuctionBuyEstimate
     */
    coupon_calculation?: CouponUsageCalculationDto;
    /**
     * 
     * @type {Auction}
     * @memberof AuctionBuyEstimate
     */
    auction?: Auction;
    /**
     * 
     * @type {User}
     * @memberof AuctionBuyEstimate
     */
    user?: User;
    /**
     * 
     * @type {UserAddress}
     * @memberof AuctionBuyEstimate
     */
    user_address?: UserAddress;
    /**
     * 
     * @type {string}
     * @memberof AuctionBuyEstimate
     */
    total_item_amount: string;
    /**
     * 
     * @type {string}
     * @memberof AuctionBuyEstimate
     */
    total_amount: string;
}
/**
 * 
 * @export
 * @interface AuctionBuyNowPromotion
 */
export interface AuctionBuyNowPromotion {
    /**
     * 
     * @type {string}
     * @memberof AuctionBuyNowPromotion
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof AuctionBuyNowPromotion
     */
    expiration: string;
}
/**
 * 
 * @export
 * @interface AuctionDto
 */
export interface AuctionDto {
    /**
     * 
     * @type {string}
     * @memberof AuctionDto
     */
    auction_id: string;
}
/**
 * 
 * @export
 * @interface AuctionHistoryDto
 */
export interface AuctionHistoryDto {
    /**
     * 
     * @type {PaginationResponseDto}
     * @memberof AuctionHistoryDto
     */
    pagination: PaginationResponseDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuctionHistoryDto
     */
    results: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AuctionHistoryDto
     */
    amount_of_bidders: number;
}
/**
 * 
 * @export
 * @interface AuctionSettingsDto
 */
export interface AuctionSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof AuctionSettingsDto
     */
    min_auth_amount: string;
    /**
     * 
     * @type {string}
     * @memberof AuctionSettingsDto
     */
    inc_auth_percentage: string;
}
/**
 * 
 * @export
 * @interface AuthDto
 */
export interface AuthDto {
    /**
     * 
     * @type {User}
     * @memberof AuthDto
     */
    user: User;
    /**
     * 
     * @type {string}
     * @memberof AuthDto
     */
    refresh_token: string;
}
/**
 * 
 * @export
 * @interface Authentication
 */
export interface Authentication {
    /**
     * 
     * @type {number}
     * @memberof Authentication
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    authenticated_at: string;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    qr_code: string;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    authentication_type: string;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    condition: string;
    /**
     * 
     * @type {boolean}
     * @memberof Authentication
     */
    autographed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    autograph_description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Authentication
     */
    artists: Array<string>;
    /**
     * 
     * @type {AuthenticationCategory}
     * @memberof Authentication
     */
    category: AuthenticationCategory;
    /**
     * 
     * @type {AuthenticationEvent}
     * @memberof Authentication
     */
    event?: AuthenticationEvent;
    /**
     * 
     * @type {Array<AuthenticationDataPoint>}
     * @memberof Authentication
     */
    data: Array<AuthenticationDataPoint>;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    item_slug?: string;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    item_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Authentication
     */
    owning?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    certificate_url?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Authentication
     */
    companion_items?: Array<string>;
    /**
     * 
     * @type {any}
     * @memberof Authentication
     */
    main_item?: any;
    /**
     * 
     * @type {Array<ReAuthentication>}
     * @memberof Authentication
     */
    re_authentications: Array<ReAuthentication>;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    action_available?: string;
}
/**
 * 
 * @export
 * @interface AuthenticationCategory
 */
export interface AuthenticationCategory {
    /**
     * 
     * @type {number}
     * @memberof AuthenticationCategory
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationCategory
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationCategory
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationCategory
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationCategory
     */
    name: string;
    /**
     * 
     * @type {AuthenticationParentCategory}
     * @memberof AuthenticationCategory
     */
    parent_category?: AuthenticationParentCategory;
}
/**
 * 
 * @export
 * @interface AuthenticationDataPoint
 */
export interface AuthenticationDataPoint {
    /**
     * 
     * @type {number}
     * @memberof AuthenticationDataPoint
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationDataPoint
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationDataPoint
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationDataPoint
     */
    type: AuthenticationDataPoint.TypeEnum;
    /**
     * 
     * @type {any}
     * @memberof AuthenticationDataPoint
     */
    value: any;
}

/**
 * @export
 * @namespace AuthenticationDataPoint
 */
export namespace AuthenticationDataPoint {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Video = <any> 'Video',
        Picture = <any> 'Picture',
        Boolean = <any> 'Boolean',
        Text = <any> 'Text',
        LongText = <any> 'LongText',
        Date = <any> 'Date',
        Options = <any> 'Options'
    }
}
/**
 * 
 * @export
 * @interface AuthenticationEvent
 */
export interface AuthenticationEvent {
    /**
     * 
     * @type {number}
     * @memberof AuthenticationEvent
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationEvent
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationEvent
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationEvent
     */
    start_date: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationEvent
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationEvent
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationEvent
     */
    partner?: string;
    /**
     * 
     * @type {AuthenticationVenue}
     * @memberof AuthenticationEvent
     */
    venue?: AuthenticationVenue;
}
/**
 * 
 * @export
 * @interface AuthenticationParentCategory
 */
export interface AuthenticationParentCategory {
    /**
     * 
     * @type {number}
     * @memberof AuthenticationParentCategory
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationParentCategory
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationParentCategory
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationParentCategory
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationParentCategory
     */
    name: string;
}
/**
 * 
 * @export
 * @interface AuthenticationVenue
 */
export interface AuthenticationVenue {
    /**
     * 
     * @type {number}
     * @memberof AuthenticationVenue
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationVenue
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationVenue
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationVenue
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationVenue
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationVenue
     */
    timezone?: string;
}
/**
 * 
 * @export
 * @interface AvailableDto
 */
export interface AvailableDto {
    /**
     * 
     * @type {boolean}
     * @memberof AvailableDto
     */
    available: boolean;
}
/**
 * 
 * @export
 * @interface BidDto
 */
export interface BidDto {
    /**
     * 
     * @type {string}
     * @memberof BidDto
     */
    payment_method_type?: BidDto.PaymentMethodTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BidDto
     */
    payment_method?: string;
    /**
     * Amount in string format with 2 decimal places
     * @type {string}
     * @memberof BidDto
     */
    amount: string;
}

/**
 * @export
 * @namespace BidDto
 */
export namespace BidDto {
    /**
     * @export
     * @enum {string}
     */
    export enum PaymentMethodTypeEnum {
        Card = <any> 'user_card',
        BankAccount = <any> 'user_bank_account'
    }
}
/**
 * 
 * @export
 * @interface BidResponseDto
 */
export interface BidResponseDto {
    /**
     * 
     * @type {Auction}
     * @memberof BidResponseDto
     */
    auction: Auction;
    /**
     * 
     * @type {AuctionBid}
     * @memberof BidResponseDto
     */
    bid: AuctionBid;
}
/**
 * 
 * @export
 * @interface BufferDto
 */
export interface BufferDto {
    /**
     * 
     * @type {string}
     * @memberof BufferDto
     */
    buffer: string;
}
/**
 * 
 * @export
 * @interface BuyDto
 */
export interface BuyDto {
    /**
     * 
     * @type {string}
     * @memberof BuyDto
     */
    payment_intent?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyDto
     */
    estimate_id: string;
}
/**
 * 
 * @export
 * @interface BuyResponseDto
 */
export interface BuyResponseDto {
    /**
     * 
     * @type {Invoice}
     * @memberof BuyResponseDto
     */
    invoice: Invoice;
}
/**
 * 
 * @export
 * @interface ChangePasswordDto
 */
export interface ChangePasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordDto
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordDto
     */
    new_password: string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordDto
     */
    new_password_confirm: string;
}
/**
 * 
 * @export
 * @interface ClientSecretDto
 */
export interface ClientSecretDto {
    /**
     * 
     * @type {string}
     * @memberof ClientSecretDto
     */
    secret: string;
}
/**
 * 
 * @export
 * @interface Coupon
 */
export interface Coupon {
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    value: string;
    /**
     * 
     * @type {boolean}
     * @memberof Coupon
     */
    active: boolean;
}
/**
 * 
 * @export
 * @interface CouponUsage
 */
export interface CouponUsage {
    /**
     * 
     * @type {string}
     * @memberof CouponUsage
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CouponUsage
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof CouponUsage
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof CouponUsage
     */
    paid_amount: string;
    /**
     * 
     * @type {string}
     * @memberof CouponUsage
     */
    off_amount: string;
    /**
     * 
     * @type {Coupon}
     * @memberof CouponUsage
     */
    coupon?: Coupon;
}
/**
 * 
 * @export
 * @interface CouponUsageCalculationDto
 */
export interface CouponUsageCalculationDto {
    /**
     * 
     * @type {string}
     * @memberof CouponUsageCalculationDto
     */
    original_amount: string;
    /**
     * 
     * @type {string}
     * @memberof CouponUsageCalculationDto
     */
    off_amount: string;
    /**
     * 
     * @type {string}
     * @memberof CouponUsageCalculationDto
     */
    final_amount: string;
    /**
     * 
     * @type {string}
     * @memberof CouponUsageCalculationDto
     */
    type: CouponUsageCalculationDto.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CouponUsageCalculationDto
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof CouponUsageCalculationDto
     */
    coupon: string;
}

/**
 * @export
 * @namespace CouponUsageCalculationDto
 */
export namespace CouponUsageCalculationDto {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        PercentageOff = <any> 'percentage_off',
        DollarOff = <any> 'dollar_off'
    }
}
/**
 * 
 * @export
 * @interface CreditLimitDto
 */
export interface CreditLimitDto {
    /**
     * 
     * @type {string}
     * @memberof CreditLimitDto
     */
    total_limit: string;
    /**
     * 
     * @type {string}
     * @memberof CreditLimitDto
     */
    available_limit: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreditLimitDto
     */
    unlimited: boolean;
}
/**
 * 
 * @export
 * @interface DeviceDto
 */
export interface DeviceDto {
    /**
     * 
     * @type {string}
     * @memberof DeviceDto
     */
    app_version: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDto
     */
    app_type?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDto
     */
    device_uuid: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDto
     */
    device_token?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDto
     */
    token_provider?: DeviceDto.TokenProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof DeviceDto
     */
    device_data_os?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDto
     */
    device_data_os_version?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDto
     */
    device_data_device_type?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDto
     */
    device_data_device_name?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDto
     */
    device_data_device_category?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceDto
     */
    debug: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeviceDto
     */
    language: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDto
     */
    timezone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceDto
     */
    send_push?: boolean;
}

/**
 * @export
 * @namespace DeviceDto
 */
export namespace DeviceDto {
    /**
     * @export
     * @enum {string}
     */
    export enum TokenProviderEnum {
        Google = <any> 'google',
        Apple = <any> 'apple',
        Jest = <any> 'jest',
        Web = <any> 'web'
    }
}
/**
 * 
 * @export
 * @interface EmailDto
 */
export interface EmailDto {
    /**
     * 
     * @type {string}
     * @memberof EmailDto
     */
    email_address: string;
}
/**
 * 
 * @export
 * @interface EmailSignInDto
 */
export interface EmailSignInDto {
    /**
     * 
     * @type {string}
     * @memberof EmailSignInDto
     */
    email_address: string;
    /**
     * 
     * @type {string}
     * @memberof EmailSignInDto
     */
    password: string;
    /**
     * 
     * @type {DeviceDto}
     * @memberof EmailSignInDto
     */
    device: DeviceDto;
}
/**
 * 
 * @export
 * @interface EmailSignUpDto
 */
export interface EmailSignUpDto {
    /**
     * 
     * @type {string}
     * @memberof EmailSignUpDto
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof EmailSignUpDto
     */
    email_address: string;
    /**
     * 
     * @type {string}
     * @memberof EmailSignUpDto
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailSignUpDto
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailSignUpDto
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof EmailSignUpDto
     */
    signed_up_from?: string;
    /**
     * 
     * @type {DeviceDto}
     * @memberof EmailSignUpDto
     */
    device: DeviceDto;
}
/**
 * 
 * @export
 * @interface Enable2FADto
 */
export interface Enable2FADto {
    /**
     * 
     * @type {string}
     * @memberof Enable2FADto
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof Enable2FADto
     */
    code: string;
}
/**
 * 
 * @export
 * @interface EstimateDto
 */
export interface EstimateDto {
    /**
     * 
     * @type {number}
     * @memberof EstimateDto
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof EstimateDto
     */
    pickup_option?: string;
    /**
     * 
     * @type {string}
     * @memberof EstimateDto
     */
    coupon_code?: string;
}
/**
 * 
 * @export
 * @interface ExpressBuyDto
 */
export interface ExpressBuyDto {
    /**
     * 
     * @type {string}
     * @memberof ExpressBuyDto
     */
    email_address: string;
    /**
     * 
     * @type {string}
     * @memberof ExpressBuyDto
     */
    payment_intent?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpressBuyDto
     */
    estimate_id: string;
    /**
     * 
     * @type {string}
     * @memberof ExpressBuyDto
     */
    name?: string;
    /**
     * 
     * @type {AddressDto}
     * @memberof ExpressBuyDto
     */
    address: AddressDto;
}
/**
 * 
 * @export
 * @interface ExpressBuyResponseDto
 */
export interface ExpressBuyResponseDto {
    /**
     * 
     * @type {Invoice}
     * @memberof ExpressBuyResponseDto
     */
    invoice: Invoice;
    /**
     * 
     * @type {string}
     * @memberof ExpressBuyResponseDto
     */
    created_user_id?: string;
}
/**
 * 
 * @export
 * @interface ExpressEstimateDto
 */
export interface ExpressEstimateDto {
    /**
     * 
     * @type {number}
     * @memberof ExpressEstimateDto
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof ExpressEstimateDto
     */
    pickup_option?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpressEstimateDto
     */
    coupon_code?: string;
    /**
     * 
     * @type {AddressDto}
     * @memberof ExpressEstimateDto
     */
    address: AddressDto;
}
/**
 * 
 * @export
 * @interface GoogleAuthDto
 */
export interface GoogleAuthDto {
    /**
     * 
     * @type {User}
     * @memberof GoogleAuthDto
     */
    user: User;
    /**
     * 
     * @type {string}
     * @memberof GoogleAuthDto
     */
    refresh_token: string;
    /**
     * 
     * @type {boolean}
     * @memberof GoogleAuthDto
     */
    created: boolean;
}
/**
 * 
 * @export
 * @interface GoogleSignUpDto
 */
export interface GoogleSignUpDto {
    /**
     * 
     * @type {string}
     * @memberof GoogleSignUpDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleSignUpDto
     */
    signed_up_from?: string;
    /**
     * 
     * @type {DeviceDto}
     * @memberof GoogleSignUpDto
     */
    device: DeviceDto;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 extends PaginatedMetaResultDto {
    /**
     * 
     * @type {Array<Item>}
     * @memberof InlineResponse200
     */
    results?: Array<Item>;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 extends PaginatedResultDto {
    /**
     * 
     * @type {Array<Item>}
     * @memberof InlineResponse2001
     */
    results?: Array<Item>;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 extends AuctionHistoryDto {
    /**
     * 
     * @type {Array<AuctionBid>}
     * @memberof InlineResponse2002
     */
    results?: Array<AuctionBid>;
}
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 extends PaginatedResultDto {
    /**
     * 
     * @type {Array<AuctionBid>}
     * @memberof InlineResponse2003
     */
    results?: Array<AuctionBid>;
}
/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    updated_at: string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    no: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    type: Invoice.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    total_amount: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    tax_amount: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    shipping_amount: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    fulfillment_method: Invoice.FulfillmentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    selected_pickup_option?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    status: Invoice.StatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    online_payment_accepted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    is_automated_payment: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    auto_pay: boolean;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    invoice_url?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    purchase_order_url?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    shipping_status: Invoice.ShippingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    shipping_tracking_no?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    shipping_track_url?: string;
    /**
     * 
     * @type {Array<AuctionBid>}
     * @memberof Invoice
     */
    auction_bids: Array<AuctionBid>;
    /**
     * 
     * @type {Array<Auction>}
     * @memberof Invoice
     */
    auction_sales: Array<Auction>;
    /**
     * 
     * @type {Array<OfferTransaction>}
     * @memberof Invoice
     */
    offer_transactions: Array<OfferTransaction>;
    /**
     * 
     * @type {Array<CouponUsage>}
     * @memberof Invoice
     */
    coupon_usages: Array<CouponUsage>;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    invoice_no_full: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    ref: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    service_fee_amount: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    original_amount: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    total_pickup_amount: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    original_amount_per_item: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    pay_url?: string;
}

/**
 * @export
 * @namespace Invoice
 */
export namespace Invoice {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Auction = <any> 'auction',
        Sale = <any> 'sale',
        Offer = <any> 'offer'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum FulfillmentMethodEnum {
        Ship = <any> 'ship',
        Pickup = <any> 'pickup'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Draft = <any> 'draft',
        Open = <any> 'open',
        Pending = <any> 'pending',
        Failed = <any> 'failed',
        Paid = <any> 'paid',
        Void = <any> 'void',
        Refunded = <any> 'refunded'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum ShippingStatusEnum {
        Pending = <any> 'pending',
        Ready = <any> 'ready',
        Shipped = <any> 'shipped',
        Received = <any> 'received'
    }
}
/**
 * 
 * @export
 * @interface Item
 */
export interface Item {
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    short_description: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    tr_id: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    authentication_type?: Item.AuthenticationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    seo_description?: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    conditions_of_sale?: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    date_posted: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    date_of_origin?: string;
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    search_status: number;
    /**
     * 
     * @type {boolean}
     * @memberof Item
     */
    private: boolean;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    private_preview_image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    type: Item.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    banner_url?: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    owner_id?: string;
    /**
     * 
     * @type {User}
     * @memberof Item
     */
    owner?: User;
    /**
     * 
     * @type {Auction}
     * @memberof Item
     */
    current_auction?: Auction;
    /**
     * 
     * @type {OfferTransaction}
     * @memberof Item
     */
    current_offer_transaction?: OfferTransaction;
    /**
     * 
     * @type {ItemCondition}
     * @memberof Item
     */
    condition: ItemCondition;
    /**
     * 
     * @type {boolean}
     * @memberof Item
     */
    autographed: boolean;
    /**
     * 
     * @type {ItemPartner}
     * @memberof Item
     */
    partner?: ItemPartner;
    /**
     * 
     * @type {ItemSubCategory}
     * @memberof Item
     */
    sub_category: ItemSubCategory;
    /**
     * 
     * @type {ItemCategory}
     * @memberof Item
     */
    category: ItemCategory;
    /**
     * 
     * @type {Array<ItemArtist>}
     * @memberof Item
     */
    artists: Array<ItemArtist>;
    /**
     * 
     * @type {Array<ItemGenre>}
     * @memberof Item
     */
    genres: Array<ItemGenre>;
    /**
     * 
     * @type {Array<ItemImage>}
     * @memberof Item
     */
    images: Array<ItemImage>;
    /**
     * 
     * @type {ItemEvent}
     * @memberof Item
     */
    event: ItemEvent;
    /**
     * 
     * @type {Array<ItemVariant>}
     * @memberof Item
     */
    variants?: Array<ItemVariant>;
    /**
     * 
     * @type {Item}
     * @memberof Item
     */
    main_item?: Item;
    /**
     * 
     * @type {Array<Item>}
     * @memberof Item
     */
    companion_items?: Array<Item>;
    /**
     * 
     * @type {boolean}
     * @memberof Item
     */
    trending: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Item
     */
    auto_accept_offer: boolean;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    min_offer_amount: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Item
     */
    suggested_offer_amounts: Array<string>;
    /**
     * 
     * @type {any}
     * @memberof Item
     */
    last_sold_amount?: any;
    /**
     * 
     * @type {boolean}
     * @memberof Item
     */
    check_offers_possible: boolean;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    offer_type?: Item.OfferTypeEnum;
}

/**
 * @export
 * @namespace Item
 */
export namespace Item {
    /**
     * @export
     * @enum {string}
     */
    export enum AuthenticationTypeEnum {
        Gold = <any> 'gold',
        Silver = <any> 'silver'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Main = <any> 'main',
        Virtual = <any> 'virtual',
        Companion = <any> 'companion',
        Giveaway = <any> 'giveaway',
        NonAuth = <any> 'non_auth'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum OfferTypeEnum {
        Auction = <any> 'auction',
        BuyNow = <any> 'buy_now',
        MakeOffer = <any> 'make_offer'
    }
}
/**
 * 
 * @export
 * @interface ItemArtist
 */
export interface ItemArtist {
    /**
     * 
     * @type {string}
     * @memberof ItemArtist
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ItemArtist
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof ItemArtist
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof ItemArtist
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ItemArtist
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof ItemArtist
     */
    biography: string;
    /**
     * 
     * @type {string}
     * @memberof ItemArtist
     */
    image_url: string;
    /**
     * 
     * @type {string}
     * @memberof ItemArtist
     */
    banner_url: string;
    /**
     * 
     * @type {Array<ItemGenre>}
     * @memberof ItemArtist
     */
    genres: Array<ItemGenre>;
}
/**
 * 
 * @export
 * @interface ItemCategory
 */
export interface ItemCategory {
    /**
     * 
     * @type {string}
     * @memberof ItemCategory
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ItemCategory
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof ItemCategory
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof ItemCategory
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ItemCategory
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof ItemCategory
     */
    description: string;
    /**
     * Colors, separated by comma (,)
     * @type {string}
     * @memberof ItemCategory
     */
    colors: string;
    /**
     * 
     * @type {Array<ItemSubCategory>}
     * @memberof ItemCategory
     */
    sub_categories: Array<ItemSubCategory>;
}
/**
 * 
 * @export
 * @interface ItemCollection
 */
export interface ItemCollection {
    /**
     * 
     * @type {string}
     * @memberof ItemCollection
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ItemCollection
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof ItemCollection
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof ItemCollection
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ItemCollection
     */
    slug: string;
    /**
     * 
     * @type {boolean}
     * @memberof ItemCollection
     */
    featured: boolean;
    /**
     * 
     * @type {string}
     * @memberof ItemCollection
     */
    start_at: string;
    /**
     * 
     * @type {Array<Array>}
     * @memberof ItemCollection
     */
    items?: Array<Array>;
}
/**
 * 
 * @export
 * @interface ItemCondition
 */
export interface ItemCondition {
    /**
     * 
     * @type {string}
     * @memberof ItemCondition
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ItemCondition
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof ItemCondition
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof ItemCondition
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ItemCondition
     */
    description: string;
}
/**
 * 
 * @export
 * @interface ItemEvent
 */
export interface ItemEvent {
    /**
     * 
     * @type {string}
     * @memberof ItemEvent
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ItemEvent
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof ItemEvent
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof ItemEvent
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ItemEvent
     */
    description: string;
    /**
     * 
     * @type {ItemEventVenue}
     * @memberof ItemEvent
     */
    venue: ItemEventVenue;
}
/**
 * 
 * @export
 * @interface ItemEventVenue
 */
export interface ItemEventVenue {
    /**
     * 
     * @type {string}
     * @memberof ItemEventVenue
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ItemEventVenue
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof ItemEventVenue
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof ItemEventVenue
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ItemEventVenue
     */
    description: string;
}
/**
 * 
 * @export
 * @interface ItemGenre
 */
export interface ItemGenre {
    /**
     * 
     * @type {string}
     * @memberof ItemGenre
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ItemGenre
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof ItemGenre
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof ItemGenre
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ItemGenre
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof ItemGenre
     */
    description: string;
    /**
     * 
     * @type {Array<ItemArtist>}
     * @memberof ItemGenre
     */
    artists: Array<ItemArtist>;
}
/**
 * 
 * @export
 * @interface ItemImage
 */
export interface ItemImage {
    /**
     * 
     * @type {string}
     * @memberof ItemImage
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ItemImage
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof ItemImage
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof ItemImage
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof ItemImage
     */
    type: ItemImage.TypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ItemImage
     */
    order: number;
    /**
     * 
     * @type {boolean}
     * @memberof ItemImage
     */
    is_poster: boolean;
}

/**
 * @export
 * @namespace ItemImage
 */
export namespace ItemImage {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Image = <any> 'image',
        Video = <any> 'video'
    }
}
/**
 * 
 * @export
 * @interface ItemPartner
 */
export interface ItemPartner {
    /**
     * 
     * @type {string}
     * @memberof ItemPartner
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ItemPartner
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof ItemPartner
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof ItemPartner
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ItemSubCategory
 */
export interface ItemSubCategory {
    /**
     * 
     * @type {string}
     * @memberof ItemSubCategory
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ItemSubCategory
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof ItemSubCategory
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof ItemSubCategory
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ItemSubCategory
     */
    slug: string;
}
/**
 * 
 * @export
 * @interface ItemVariant
 */
export interface ItemVariant {
    /**
     * 
     * @type {string}
     * @memberof ItemVariant
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof ItemVariant
     */
    name: string;
}
/**
 * 
 * @export
 * @interface LatestOfferDto
 */
export interface LatestOfferDto {
    /**
     * 
     * @type {Item}
     * @memberof LatestOfferDto
     */
    item: Item;
    /**
     * 
     * @type {Offer}
     * @memberof LatestOfferDto
     */
    offer: Offer;
}
/**
 * 
 * @export
 * @interface MeProfileimageBody
 */
export interface MeProfileimageBody {
    /**
     * 
     * @type {Blob}
     * @memberof MeProfileimageBody
     */
    file?: Blob;
}
/**
 * 
 * @export
 * @interface MessagingSubscriptionCodeResponseDto
 */
export interface MessagingSubscriptionCodeResponseDto {
    /**
     * 
     * @type {string}
     * @memberof MessagingSubscriptionCodeResponseDto
     */
    status: MessagingSubscriptionCodeResponseDto.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MessagingSubscriptionCodeResponseDto
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof MessagingSubscriptionCodeResponseDto
     */
    subscribe_phone_number: string;
}

/**
 * @export
 * @namespace MessagingSubscriptionCodeResponseDto
 */
export namespace MessagingSubscriptionCodeResponseDto {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Pending = <any> 'pending',
        Subscribed = <any> 'subscribed',
        UnSubscribed = <any> 'un_subscribed'
    }
}
/**
 * 
 * @export
 * @interface MetaAvailableDto
 */
export interface MetaAvailableDto {
    /**
     * 
     * @type {string}
     * @memberof MetaAvailableDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof MetaAvailableDto
     */
    available: number;
}
/**
 * 
 * @export
 * @interface NewsletterDto
 */
export interface NewsletterDto {
    /**
     * 
     * @type {string}
     * @memberof NewsletterDto
     */
    full_name?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterDto
     */
    email_address: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterDto
     */
    newsletter: string;
}
/**
 * 
 * @export
 * @interface Offer
 */
export interface Offer {
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    counter_amount: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    status: Offer.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    expires_at: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    item_id: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    user_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof Offer
     */
    user_read: boolean;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    to_owner_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof Offer
     */
    to_owner_read: boolean;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    current_amount: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    estimated_payout_amount?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Offer
     */
    is_accepted: boolean;
}

/**
 * @export
 * @namespace Offer
 */
export namespace Offer {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Pending = <any> 'pending',
        Rejected = <any> 'rejected',
        Countered = <any> 'countered',
        Accepted = <any> 'accepted',
        CounterAccepted = <any> 'counter_accepted',
        CounterRejected = <any> 'counter_rejected'
    }
}
/**
 * 
 * @export
 * @interface OfferTransaction
 */
export interface OfferTransaction {
    /**
     * 
     * @type {string}
     * @memberof OfferTransaction
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OfferTransaction
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof OfferTransaction
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof OfferTransaction
     */
    conclusion_mode: OfferTransaction.ConclusionModeEnum;
    /**
     * 
     * @type {string}
     * @memberof OfferTransaction
     */
    status: OfferTransaction.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof OfferTransaction
     */
    shipping_status: OfferTransaction.ShippingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof OfferTransaction
     */
    payout_status: string;
    /**
     * 
     * @type {string}
     * @memberof OfferTransaction
     */
    shipping_track_url?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferTransaction
     */
    offer_id: string;
    /**
     * 
     * @type {Offer}
     * @memberof OfferTransaction
     */
    offer: Offer;
    /**
     * 
     * @type {string}
     * @memberof OfferTransaction
     */
    item_id: string;
    /**
     * 
     * @type {Item}
     * @memberof OfferTransaction
     */
    item: Item;
    /**
     * 
     * @type {string}
     * @memberof OfferTransaction
     */
    invoice_id: string;
    /**
     * 
     * @type {string}
     * @memberof OfferTransaction
     */
    current_amount: string;
    /**
     * 
     * @type {string}
     * @memberof OfferTransaction
     */
    offer_fee: string;
    /**
     * 
     * @type {string}
     * @memberof OfferTransaction
     */
    payout_amount: string;
}

/**
 * @export
 * @namespace OfferTransaction
 */
export namespace OfferTransaction {
    /**
     * @export
     * @enum {string}
     */
    export enum ConclusionModeEnum {
        Auto = <any> 'auto',
        Counter = <any> 'counter',
        Owner = <any> 'owner'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Pending = <any> 'pending',
        Cancelled = <any> 'cancelled',
        Paid = <any> 'paid',
        OfficeReceived = <any> 'office_received',
        Reauthenticated = <any> 'reauthenticated',
        ReauthenticatedFailed = <any> 'reauthenticated_failed',
        ShippedToBuyer = <any> 'shipped_to_buyer',
        Done = <any> 'done'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum ShippingStatusEnum {
        Pending = <any> 'pending',
        Ready = <any> 'ready',
        Shipped = <any> 'shipped',
        Received = <any> 'received'
    }
}
/**
 * 
 * @export
 * @interface OffersPossibleDto
 */
export interface OffersPossibleDto {
    /**
     * 
     * @type {boolean}
     * @memberof OffersPossibleDto
     */
    offers_possible: boolean;
}
/**
 * 
 * @export
 * @interface PaginatedMetaResultDto
 */
export interface PaginatedMetaResultDto {
    /**
     * 
     * @type {PaginationResponseDto}
     * @memberof PaginatedMetaResultDto
     */
    pagination: PaginationResponseDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaginatedMetaResultDto
     */
    results: Array<string>;
    /**
     * 
     * @type {any}
     * @memberof PaginatedMetaResultDto
     */
    meta_available: any;
}
/**
 * 
 * @export
 * @interface PaginatedResultDto
 */
export interface PaginatedResultDto {
    /**
     * 
     * @type {PaginationResponseDto}
     * @memberof PaginatedResultDto
     */
    pagination: PaginationResponseDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaginatedResultDto
     */
    results: Array<string>;
}
/**
 * 
 * @export
 * @interface PaginationResponseDto
 */
export interface PaginationResponseDto {
    /**
     * 
     * @type {number}
     * @memberof PaginationResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponseDto
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponseDto
     */
    offset: number;
}
/**
 * 
 * @export
 * @interface PaymentAccountDto
 */
export interface PaymentAccountDto {
    /**
     * 
     * @type {Array<UserCard>}
     * @memberof PaymentAccountDto
     */
    cards: Array<UserCard>;
    /**
     * 
     * @type {Array<UserBankAccount>}
     * @memberof PaymentAccountDto
     */
    bank_accounts: Array<UserBankAccount>;
}
/**
 * 
 * @export
 * @interface PaymentDto
 */
export interface PaymentDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    payment_method_type: PaymentDto.PaymentMethodTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    payment_method: string;
}

/**
 * @export
 * @namespace PaymentDto
 */
export namespace PaymentDto {
    /**
     * @export
     * @enum {string}
     */
    export enum PaymentMethodTypeEnum {
        Card = <any> 'user_card',
        BankAccount = <any> 'user_bank_account'
    }
}
/**
 * 
 * @export
 * @interface PaymentIntentDto
 */
export interface PaymentIntentDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentIntentDto
     */
    payment_intent: string;
}
/**
 * 
 * @export
 * @interface ReAuthentication
 */
export interface ReAuthentication {
    /**
     * 
     * @type {number}
     * @memberof ReAuthentication
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ReAuthentication
     */
    re_auth_at: string;
    /**
     * 
     * @type {string}
     * @memberof ReAuthentication
     */
    reason: string;
    /**
     * 
     * @type {string}
     * @memberof ReAuthentication
     */
    condition: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReAuthentication
     */
    autographed?: boolean;
    /**
     * 
     * @type {Array<AuthenticationDataPoint>}
     * @memberof ReAuthentication
     */
    data: Array<AuthenticationDataPoint>;
}
/**
 * 
 * @export
 * @interface Request2FADto
 */
export interface Request2FADto {
    /**
     * 
     * @type {string}
     * @memberof Request2FADto
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface ResetPasswordDto
 */
export interface ResetPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordDto
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordDto
     */
    password: string;
}
/**
 * 
 * @export
 * @interface SetupIntentDto
 */
export interface SetupIntentDto {
    /**
     * 
     * @type {string}
     * @memberof SetupIntentDto
     */
    client_secret: string;
}
/**
 * 
 * @export
 * @interface StatusDto
 */
export interface StatusDto {
    /**
     * 
     * @type {string}
     * @memberof StatusDto
     */
    status: string;
}
/**
 * 
 * @export
 * @interface SubmitOfferDto
 */
export interface SubmitOfferDto {
    /**
     * Amount in string format with 2 decimal places
     * @type {string}
     * @memberof SubmitOfferDto
     */
    amount: string;
}
/**
 * 
 * @export
 * @interface UpdateDeliveryMethodDto
 */
export interface UpdateDeliveryMethodDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliveryMethodDto
     */
    pickup_option?: string;
}
/**
 * 
 * @export
 * @interface UpdateMinOfferDto
 */
export interface UpdateMinOfferDto {
    /**
     * Amount in string format with 2 decimal places
     * @type {string}
     * @memberof UpdateMinOfferDto
     */
    amount: string;
    /**
     * Instant offer
     * @type {boolean}
     * @memberof UpdateMinOfferDto
     */
    auto_accept_offer: boolean;
}
/**
 * 
 * @export
 * @interface UpdateUserDto
 */
export interface UpdateUserDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    onboarding_status?: string;
}
/**
 * 
 * @export
 * @interface UrlDto
 */
export interface UrlDto {
    /**
     * 
     * @type {string}
     * @memberof UrlDto
     */
    url: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email_address?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    username: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    is_email_confirmed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    is_two_factor_enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    profile_image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    stripe_connected_status: User.StripeConnectedStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    kyc_verification_status?: User.KycVerificationStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    allow_vip_bidding?: boolean;
    /**
     * 
     * @type {UserCreditLimitOpportunitiesUsed}
     * @memberof User
     */
    credit_limit_opportunities_used?: UserCreditLimitOpportunitiesUsed;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    onboarding_status?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    two_factor_phone_number_last4?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    socket_eligible: boolean;
}

/**
 * @export
 * @namespace User
 */
export namespace User {
    /**
     * @export
     * @enum {string}
     */
    export enum StripeConnectedStatusEnum {
        NotConnected = <any> 'not_connected',
        RequiresInput = <any> 'requires_input',
        Verified = <any> 'verified'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum KycVerificationStatusEnum {
        Canceled = <any> 'canceled',
        Processing = <any> 'processing',
        RequiresInput = <any> 'requires_input',
        Verified = <any> 'verified'
    }
}
/**
 * 
 * @export
 * @interface UserAddress
 */
export interface UserAddress {
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    full_name: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    line_1: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    line_2?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    postal_code: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    mode: UserAddress.ModeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    user_id?: string;
}

/**
 * @export
 * @namespace UserAddress
 */
export namespace UserAddress {
    /**
     * @export
     * @enum {string}
     */
    export enum ModeEnum {
        None = <any> 'none',
        Shipping = <any> 'shipping',
        Billing = <any> 'billing',
        Both = <any> 'both'
    }
}
/**
 * 
 * @export
 * @interface UserAlert
 */
export interface UserAlert {
    /**
     * 
     * @type {string}
     * @memberof UserAlert
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserAlert
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof UserAlert
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof UserAlert
     */
    type: UserAlert.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserAlert
     */
    related_id: string;
    /**
     * 
     * @type {string}
     * @memberof UserAlert
     */
    related_type: string;
    /**
     * 
     * @type {string}
     * @memberof UserAlert
     */
    image_url: string;
    /**
     * 
     * @type {string}
     * @memberof UserAlert
     */
    link: string;
    /**
     * 
     * @type {string}
     * @memberof UserAlert
     */
    message: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserAlert
     */
    read: boolean;
}

/**
 * @export
 * @namespace UserAlert
 */
export namespace UserAlert {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        WELCOME = <any> 'WELCOME',
        ONBOARDINGREMINDER = <any> 'ONBOARDING_REMINDER',
        AUCTIONOUTBID = <any> 'AUCTION_OUTBID',
        AUCTIONWON = <any> 'AUCTION_WON',
        AUCTIONSTARTING = <any> 'AUCTION_STARTING',
        AUCTIONENDING = <any> 'AUCTION_ENDING',
        OFFERNEW = <any> 'OFFER_NEW',
        OFFERREJECTED = <any> 'OFFER_REJECTED',
        OFFERCOUNTERREJECTED = <any> 'OFFER_COUNTER_REJECTED',
        OFFERCOUNTERED = <any> 'OFFER_COUNTERED',
        SECONDARYSOLD = <any> 'SECONDARY_SOLD',
        SECONDARYBOUGHT = <any> 'SECONDARY_BOUGHT',
        SECONDARYINREAUTHENTICAATION = <any> 'SECONDARY_IN_REAUTHENTICAATION',
        ITEMSHIPPED = <any> 'ITEM_SHIPPED'
    }
}
/**
 * 
 * @export
 * @interface UserBankAccount
 */
export interface UserBankAccount {
    /**
     * 
     * @type {string}
     * @memberof UserBankAccount
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserBankAccount
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof UserBankAccount
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof UserBankAccount
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof UserBankAccount
     */
    bank_name: string;
    /**
     * 
     * @type {string}
     * @memberof UserBankAccount
     */
    account_type: string;
    /**
     * 
     * @type {string}
     * @memberof UserBankAccount
     */
    account_holder_type: string;
    /**
     * 
     * @type {string}
     * @memberof UserBankAccount
     */
    last4: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserBankAccount
     */
    is_default: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserBankAccount
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserBankAccount
     */
    user_id: string;
}
/**
 * 
 * @export
 * @interface UserCard
 */
export interface UserCard {
    /**
     * 
     * @type {string}
     * @memberof UserCard
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserCard
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof UserCard
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof UserCard
     */
    brand: string;
    /**
     * 
     * @type {string}
     * @memberof UserCard
     */
    country: string;
    /**
     * 
     * @type {number}
     * @memberof UserCard
     */
    exp_month: number;
    /**
     * 
     * @type {number}
     * @memberof UserCard
     */
    exp_year: number;
    /**
     * 
     * @type {string}
     * @memberof UserCard
     */
    last4: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserCard
     */
    is_default: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserCard
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCard
     */
    user_id: string;
}
/**
 * 
 * @export
 * @interface UserCredit
 */
export interface UserCredit {
    /**
     * 
     * @type {string}
     * @memberof UserCredit
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserCredit
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof UserCredit
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof UserCredit
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof UserCredit
     */
    operation: UserCredit.OperationEnum;
    /**
     * 
     * @type {Auction}
     * @memberof UserCredit
     */
    auction?: Auction;
    /**
     * 
     * @type {string}
     * @memberof UserCredit
     */
    user_id: string;
}

/**
 * @export
 * @namespace UserCredit
 */
export namespace UserCredit {
    /**
     * @export
     * @enum {string}
     */
    export enum OperationEnum {
        Add = <any> 'add',
        Subtract = <any> 'subtract'
    }
}
/**
 * 
 * @export
 * @interface UserCreditLimitOpportunitiesUsed
 */
export interface UserCreditLimitOpportunitiesUsed {
    /**
     * 
     * @type {boolean}
     * @memberof UserCreditLimitOpportunitiesUsed
     */
    email_verified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreditLimitOpportunitiesUsed
     */
    credit_card_added?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreditLimitOpportunitiesUsed
     */
    bank_account_added?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreditLimitOpportunitiesUsed
     */
    kyc_completed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreditLimitOpportunitiesUsed
     */
    address_added?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreditLimitOpportunitiesUsed
     */
    activate_2fa?: boolean;
}
/**
 * 
 * @export
 * @interface UsernameDto
 */
export interface UsernameDto {
    /**
     * 
     * @type {string}
     * @memberof UsernameDto
     */
    username: string;
}
/**
 * 
 * @export
 * @interface Verify2FADto
 */
export interface Verify2FADto {
    /**
     * 
     * @type {string}
     * @memberof Verify2FADto
     */
    code: string;
}
/**
 * 
 * @export
 * @interface VerifyEmailDto
 */
export interface VerifyEmailDto {
    /**
     * 
     * @type {string}
     * @memberof VerifyEmailDto
     */
    email_token: string;
}
/**
 * 
 * @export
 * @interface VerifyResetTokenDto
 */
export interface VerifyResetTokenDto {
    /**
     * 
     * @type {string}
     * @memberof VerifyResetTokenDto
     */
    token: string;
}
/**
 * 
 * @export
 * @interface WsBidDto
 */
export interface WsBidDto {
    /**
     * 
     * @type {string}
     * @memberof WsBidDto
     */
    payment_method_type?: WsBidDto.PaymentMethodTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof WsBidDto
     */
    payment_method?: string;
    /**
     * Amount in string format with 2 decimal places
     * @type {string}
     * @memberof WsBidDto
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof WsBidDto
     */
    auction_id: string;
}

/**
 * @export
 * @namespace WsBidDto
 */
export namespace WsBidDto {
    /**
     * @export
     * @enum {string}
     */
    export enum PaymentMethodTypeEnum {
        Card = <any> 'user_card',
        BankAccount = <any> 'user_bank_account'
    }
}
/**
 * AuctionApi - fetch parameter creator
 * @export
 */
export const AuctionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Bid on Auction
         * @param {BidDto} body 
         * @param {string} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerBidOnAuction(body: BidDto, auctionId: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling auctionControllerBidOnAuction.');
            }
            // verify required parameter 'auctionId' is not null or undefined
            if (auctionId === null || auctionId === undefined) {
                throw new RequiredError('auctionId','Required parameter auctionId was null or undefined when calling auctionControllerBidOnAuction.');
            }
            const localVarPath = `/v1/auction/{auctionId}/bid`
                .replace(`{${'auctionId'}}`, encodeURIComponent(String(auctionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'BidDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get estimate for item
         * @param {EstimateDto} body 
         * @param {string} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerBuyEstimateItem(body: EstimateDto, auctionId: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling auctionControllerBuyEstimateItem.');
            }
            // verify required parameter 'auctionId' is not null or undefined
            if (auctionId === null || auctionId === undefined) {
                throw new RequiredError('auctionId','Required parameter auctionId was null or undefined when calling auctionControllerBuyEstimateItem.');
            }
            const localVarPath = `/v1/auction/{auctionId}/buy/estimate`
                .replace(`{${'auctionId'}}`, encodeURIComponent(String(auctionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'EstimateDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get estimate for item (express)
         * @param {ExpressEstimateDto} body 
         * @param {string} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerBuyEstimateItemExpress(body: ExpressEstimateDto, auctionId: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling auctionControllerBuyEstimateItemExpress.');
            }
            // verify required parameter 'auctionId' is not null or undefined
            if (auctionId === null || auctionId === undefined) {
                throw new RequiredError('auctionId','Required parameter auctionId was null or undefined when calling auctionControllerBuyEstimateItemExpress.');
            }
            const localVarPath = `/v1/auction/{auctionId}/buy/express/estimate`
                .replace(`{${'auctionId'}}`, encodeURIComponent(String(auctionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'ExpressEstimateDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Buy an Item
         * @param {BuyDto} body 
         * @param {string} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerBuyItem(body: BuyDto, auctionId: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling auctionControllerBuyItem.');
            }
            // verify required parameter 'auctionId' is not null or undefined
            if (auctionId === null || auctionId === undefined) {
                throw new RequiredError('auctionId','Required parameter auctionId was null or undefined when calling auctionControllerBuyItem.');
            }
            const localVarPath = `/v1/auction/{auctionId}/buy`
                .replace(`{${'auctionId'}}`, encodeURIComponent(String(auctionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'BuyDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Buy an Item
         * @param {ExpressBuyDto} body 
         * @param {string} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerBuyItemExpress(body: ExpressBuyDto, auctionId: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling auctionControllerBuyItemExpress.');
            }
            // verify required parameter 'auctionId' is not null or undefined
            if (auctionId === null || auctionId === undefined) {
                throw new RequiredError('auctionId','Required parameter auctionId was null or undefined when calling auctionControllerBuyItemExpress.');
            }
            const localVarPath = `/v1/auction/{auctionId}/express/buy`
                .replace(`{${'auctionId'}}`, encodeURIComponent(String(auctionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'ExpressBuyDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check auction access
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerGetAuctionAccess(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling auctionControllerGetAuctionAccess.');
            }
            const localVarPath = `/v1/auction/{id}/access`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get auction bid history
         * @param {string} id 
         * @param {number} [offset] Result cursor (amount of results to skip)
         * @param {number} [limit] Limit results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerGetAuctionBidHistory(id: string, offset?: number, limit?: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling auctionControllerGetAuctionBidHistory.');
            }
            const localVarPath = `/v1/auction/{id}/bid-history`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get auction last bid
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerGetAuctionLastBid(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling auctionControllerGetAuctionLastBid.');
            }
            const localVarPath = `/v1/auction/{id}/last-bid`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get auctions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerGetAuctions(options: any = {}): FetchArgs {
            const localVarPath = `/v1/auction`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user bid history
         * @param {number} [offset] Result cursor (amount of results to skip)
         * @param {number} [limit] Limit results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerGetUserBidHistory(offset?: number, limit?: number, options: any = {}): FetchArgs {
            const localVarPath = `/v1/auction/bid-history`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuctionApi - functional programming interface
 * @export
 */
export const AuctionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Bid on Auction
         * @param {BidDto} body 
         * @param {string} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerBidOnAuction(body: BidDto, auctionId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BidResponseDto> {
            const localVarFetchArgs = AuctionApiFetchParamCreator(configuration).auctionControllerBidOnAuction(body, auctionId, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get estimate for item
         * @param {EstimateDto} body 
         * @param {string} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerBuyEstimateItem(body: EstimateDto, auctionId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuctionBuyEstimate> {
            const localVarFetchArgs = AuctionApiFetchParamCreator(configuration).auctionControllerBuyEstimateItem(body, auctionId, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get estimate for item (express)
         * @param {ExpressEstimateDto} body 
         * @param {string} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerBuyEstimateItemExpress(body: ExpressEstimateDto, auctionId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuctionBuyEstimate> {
            const localVarFetchArgs = AuctionApiFetchParamCreator(configuration).auctionControllerBuyEstimateItemExpress(body, auctionId, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Buy an Item
         * @param {BuyDto} body 
         * @param {string} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerBuyItem(body: BuyDto, auctionId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BuyResponseDto> {
            const localVarFetchArgs = AuctionApiFetchParamCreator(configuration).auctionControllerBuyItem(body, auctionId, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Buy an Item
         * @param {ExpressBuyDto} body 
         * @param {string} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerBuyItemExpress(body: ExpressBuyDto, auctionId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ExpressBuyResponseDto> {
            const localVarFetchArgs = AuctionApiFetchParamCreator(configuration).auctionControllerBuyItemExpress(body, auctionId, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Check auction access
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerGetAuctionAccess(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StatusDto> {
            const localVarFetchArgs = AuctionApiFetchParamCreator(configuration).auctionControllerGetAuctionAccess(id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get auction bid history
         * @param {string} id 
         * @param {number} [offset] Result cursor (amount of results to skip)
         * @param {number} [limit] Limit results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerGetAuctionBidHistory(id: string, offset?: number, limit?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
            const localVarFetchArgs = AuctionApiFetchParamCreator(configuration).auctionControllerGetAuctionBidHistory(id, offset, limit, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get auction last bid
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerGetAuctionLastBid(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuctionBid> {
            const localVarFetchArgs = AuctionApiFetchParamCreator(configuration).auctionControllerGetAuctionLastBid(id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get auctions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerGetAuctions(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Auction>> {
            const localVarFetchArgs = AuctionApiFetchParamCreator(configuration).auctionControllerGetAuctions(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get user bid history
         * @param {number} [offset] Result cursor (amount of results to skip)
         * @param {number} [limit] Limit results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerGetUserBidHistory(offset?: number, limit?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
            const localVarFetchArgs = AuctionApiFetchParamCreator(configuration).auctionControllerGetUserBidHistory(offset, limit, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuctionApi - factory interface
 * @export
 */
export const AuctionApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Bid on Auction
         * @param {BidDto} body 
         * @param {string} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerBidOnAuction(body: BidDto, auctionId: string, options?: any) {
            return AuctionApiFp(configuration).auctionControllerBidOnAuction(body, auctionId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get estimate for item
         * @param {EstimateDto} body 
         * @param {string} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerBuyEstimateItem(body: EstimateDto, auctionId: string, options?: any) {
            return AuctionApiFp(configuration).auctionControllerBuyEstimateItem(body, auctionId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get estimate for item (express)
         * @param {ExpressEstimateDto} body 
         * @param {string} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerBuyEstimateItemExpress(body: ExpressEstimateDto, auctionId: string, options?: any) {
            return AuctionApiFp(configuration).auctionControllerBuyEstimateItemExpress(body, auctionId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Buy an Item
         * @param {BuyDto} body 
         * @param {string} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerBuyItem(body: BuyDto, auctionId: string, options?: any) {
            return AuctionApiFp(configuration).auctionControllerBuyItem(body, auctionId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Buy an Item
         * @param {ExpressBuyDto} body 
         * @param {string} auctionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerBuyItemExpress(body: ExpressBuyDto, auctionId: string, options?: any) {
            return AuctionApiFp(configuration).auctionControllerBuyItemExpress(body, auctionId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Check auction access
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerGetAuctionAccess(id: string, options?: any) {
            return AuctionApiFp(configuration).auctionControllerGetAuctionAccess(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get auction bid history
         * @param {string} id 
         * @param {number} [offset] Result cursor (amount of results to skip)
         * @param {number} [limit] Limit results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerGetAuctionBidHistory(id: string, offset?: number, limit?: number, options?: any) {
            return AuctionApiFp(configuration).auctionControllerGetAuctionBidHistory(id, offset, limit, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get auction last bid
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerGetAuctionLastBid(id: string, options?: any) {
            return AuctionApiFp(configuration).auctionControllerGetAuctionLastBid(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get auctions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerGetAuctions(options?: any) {
            return AuctionApiFp(configuration).auctionControllerGetAuctions(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get user bid history
         * @param {number} [offset] Result cursor (amount of results to skip)
         * @param {number} [limit] Limit results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auctionControllerGetUserBidHistory(offset?: number, limit?: number, options?: any) {
            return AuctionApiFp(configuration).auctionControllerGetUserBidHistory(offset, limit, options)(fetch, basePath);
        },
    };
};

/**
 * AuctionApi - interface
 * @export
 * @interface AuctionApi
 */
export interface AuctionApiInterface {
    /**
     * 
     * @summary Bid on Auction
     * @param {BidDto} body 
     * @param {string} auctionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApiInterface
     */
    auctionControllerBidOnAuction(body: BidDto, auctionId: string, options?: any): Promise<BidResponseDto>;

    /**
     * 
     * @summary Get estimate for item
     * @param {EstimateDto} body 
     * @param {string} auctionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApiInterface
     */
    auctionControllerBuyEstimateItem(body: EstimateDto, auctionId: string, options?: any): Promise<AuctionBuyEstimate>;

    /**
     * 
     * @summary Get estimate for item (express)
     * @param {ExpressEstimateDto} body 
     * @param {string} auctionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApiInterface
     */
    auctionControllerBuyEstimateItemExpress(body: ExpressEstimateDto, auctionId: string, options?: any): Promise<AuctionBuyEstimate>;

    /**
     * 
     * @summary Buy an Item
     * @param {BuyDto} body 
     * @param {string} auctionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApiInterface
     */
    auctionControllerBuyItem(body: BuyDto, auctionId: string, options?: any): Promise<BuyResponseDto>;

    /**
     * 
     * @summary Buy an Item
     * @param {ExpressBuyDto} body 
     * @param {string} auctionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApiInterface
     */
    auctionControllerBuyItemExpress(body: ExpressBuyDto, auctionId: string, options?: any): Promise<ExpressBuyResponseDto>;

    /**
     * 
     * @summary Check auction access
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApiInterface
     */
    auctionControllerGetAuctionAccess(id: string, options?: any): Promise<StatusDto>;

    /**
     * 
     * @summary Get auction bid history
     * @param {string} id 
     * @param {number} [offset] Result cursor (amount of results to skip)
     * @param {number} [limit] Limit results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApiInterface
     */
    auctionControllerGetAuctionBidHistory(id: string, offset?: number, limit?: number, options?: any): Promise<InlineResponse2002>;

    /**
     * 
     * @summary Get auction last bid
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApiInterface
     */
    auctionControllerGetAuctionLastBid(id: string, options?: any): Promise<AuctionBid>;

    /**
     * 
     * @summary Get auctions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApiInterface
     */
    auctionControllerGetAuctions(options?: any): Promise<Array<Auction>>;

    /**
     * 
     * @summary Get user bid history
     * @param {number} [offset] Result cursor (amount of results to skip)
     * @param {number} [limit] Limit results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApiInterface
     */
    auctionControllerGetUserBidHistory(offset?: number, limit?: number, options?: any): Promise<InlineResponse2003>;

}

/**
 * AuctionApi - object-oriented interface
 * @export
 * @class AuctionApi
 * @extends {BaseAPI}
 */
export class AuctionApi extends BaseAPI implements AuctionApiInterface {
    /**
     * 
     * @summary Bid on Auction
     * @param {BidDto} body 
     * @param {string} auctionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public auctionControllerBidOnAuction(body: BidDto, auctionId: string, options?: any) {
        return AuctionApiFp(this.configuration).auctionControllerBidOnAuction(body, auctionId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get estimate for item
     * @param {EstimateDto} body 
     * @param {string} auctionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public auctionControllerBuyEstimateItem(body: EstimateDto, auctionId: string, options?: any) {
        return AuctionApiFp(this.configuration).auctionControllerBuyEstimateItem(body, auctionId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get estimate for item (express)
     * @param {ExpressEstimateDto} body 
     * @param {string} auctionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public auctionControllerBuyEstimateItemExpress(body: ExpressEstimateDto, auctionId: string, options?: any) {
        return AuctionApiFp(this.configuration).auctionControllerBuyEstimateItemExpress(body, auctionId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Buy an Item
     * @param {BuyDto} body 
     * @param {string} auctionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public auctionControllerBuyItem(body: BuyDto, auctionId: string, options?: any) {
        return AuctionApiFp(this.configuration).auctionControllerBuyItem(body, auctionId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Buy an Item
     * @param {ExpressBuyDto} body 
     * @param {string} auctionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public auctionControllerBuyItemExpress(body: ExpressBuyDto, auctionId: string, options?: any) {
        return AuctionApiFp(this.configuration).auctionControllerBuyItemExpress(body, auctionId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Check auction access
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public auctionControllerGetAuctionAccess(id: string, options?: any) {
        return AuctionApiFp(this.configuration).auctionControllerGetAuctionAccess(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get auction bid history
     * @param {string} id 
     * @param {number} [offset] Result cursor (amount of results to skip)
     * @param {number} [limit] Limit results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public auctionControllerGetAuctionBidHistory(id: string, offset?: number, limit?: number, options?: any) {
        return AuctionApiFp(this.configuration).auctionControllerGetAuctionBidHistory(id, offset, limit, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get auction last bid
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public auctionControllerGetAuctionLastBid(id: string, options?: any) {
        return AuctionApiFp(this.configuration).auctionControllerGetAuctionLastBid(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get auctions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public auctionControllerGetAuctions(options?: any) {
        return AuctionApiFp(this.configuration).auctionControllerGetAuctions(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get user bid history
     * @param {number} [offset] Result cursor (amount of results to skip)
     * @param {number} [limit] Limit results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuctionApi
     */
    public auctionControllerGetUserBidHistory(offset?: number, limit?: number, options?: any) {
        return AuctionApiFp(this.configuration).auctionControllerGetUserBidHistory(offset, limit, options)(this.fetch, this.basePath);
    }

}
/**
 * AuthApi - fetch parameter creator
 * @export
 */
export const AuthApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change password
         * @param {ChangePasswordDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerChangePassword(body: ChangePasswordDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authControllerChangePassword.');
            }
            const localVarPath = `/v1/auth/change-password`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'ChangePasswordDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disable 2FA Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerDisable2FACode(options: any = {}): FetchArgs {
            const localVarPath = `/v1/auth/mfa/disable`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable 2FA Code
         * @param {Enable2FADto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerEnable2FACode(body: Enable2FADto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authControllerEnable2FACode.');
            }
            const localVarPath = `/v1/auth/mfa/enable`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'Enable2FADto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request reset password
         * @param {EmailDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerForgotPassword(body: EmailDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authControllerForgotPassword.');
            }
            const localVarPath = `/v1/auth/forgot-password/request`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'EmailDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogout(options: any = {}): FetchArgs {
            const localVarPath = `/v1/auth/logout`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get new Access Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefresh(options: any = {}): FetchArgs {
            const localVarPath = `/v1/auth/refresh`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication refresh required


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request 2FA Code
         * @param {Request2FADto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRequest2FACode(body: Request2FADto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authControllerRequest2FACode.');
            }
            const localVarPath = `/v1/auth/mfa/request`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'Request2FADto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend 2FA Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResendMfa(options: any = {}): FetchArgs {
            const localVarPath = `/v1/auth/mfa/resend`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend verification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResendVerification(options: any = {}): FetchArgs {
            const localVarPath = `/v1/auth/resend-verification`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset password
         * @param {ResetPasswordDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResetPasswordByToken(body: ResetPasswordDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authControllerResetPasswordByToken.');
            }
            const localVarPath = `/v1/auth/forgot-password/reset`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'ResetPasswordDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign in via Email
         * @param {EmailSignInDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignInEmail(body: EmailSignInDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authControllerSignInEmail.');
            }
            const localVarPath = `/v1/auth/signin/email`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'EmailSignInDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign in via Google
         * @param {GoogleSignUpDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignInGoogle(body: GoogleSignUpDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authControllerSignInGoogle.');
            }
            const localVarPath = `/v1/auth/signin/google`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'GoogleSignUpDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign Up via Email
         * @param {EmailSignUpDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignUp(body: EmailSignUpDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authControllerSignUp.');
            }
            const localVarPath = `/v1/auth/signup/email`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'EmailSignUpDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check username availability
         * @param {UsernameDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerUsernameAvailable(body: UsernameDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authControllerUsernameAvailable.');
            }
            const localVarPath = `/v1/auth/username/check`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'UsernameDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify 2FA Code
         * @param {Verify2FADto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerify2FACode(body: Verify2FADto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authControllerVerify2FACode.');
            }
            const localVarPath = `/v1/auth/mfa/verify`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'Verify2FADto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify email address
         * @param {VerifyEmailDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerifyEmail(body: VerifyEmailDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authControllerVerifyEmail.');
            }
            const localVarPath = `/v1/auth/verify-email`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'VerifyEmailDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify reset password token
         * @param {VerifyResetTokenDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerifyForgotPasswordToken(body: VerifyResetTokenDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authControllerVerifyForgotPasswordToken.');
            }
            const localVarPath = `/v1/auth/forgot-password/verify`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'VerifyResetTokenDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change password
         * @param {ChangePasswordDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerChangePassword(body: ChangePasswordDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<User> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authControllerChangePassword(body, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Disable 2FA Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerDisable2FACode(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<User> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authControllerDisable2FACode(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Enable 2FA Code
         * @param {Enable2FADto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerEnable2FACode(body: Enable2FADto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthDto> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authControllerEnable2FACode(body, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Request reset password
         * @param {EmailDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerForgotPassword(body: EmailDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authControllerForgotPassword(body, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogout(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authControllerLogout(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get new Access Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefresh(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthDto> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authControllerRefresh(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Request 2FA Code
         * @param {Request2FADto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRequest2FACode(body: Request2FADto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StatusDto> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authControllerRequest2FACode(body, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Resend 2FA Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResendMfa(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StatusDto> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authControllerResendMfa(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Resend verification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResendVerification(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StatusDto> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authControllerResendVerification(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Reset password
         * @param {ResetPasswordDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResetPasswordByToken(body: ResetPasswordDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EmailDto> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authControllerResetPasswordByToken(body, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Sign in via Email
         * @param {EmailSignInDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignInEmail(body: EmailSignInDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthDto> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authControllerSignInEmail(body, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Sign in via Google
         * @param {GoogleSignUpDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignInGoogle(body: GoogleSignUpDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GoogleAuthDto> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authControllerSignInGoogle(body, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Sign Up via Email
         * @param {EmailSignUpDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignUp(body: EmailSignUpDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthDto> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authControllerSignUp(body, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Check username availability
         * @param {UsernameDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerUsernameAvailable(body: UsernameDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AvailableDto> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authControllerUsernameAvailable(body, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Verify 2FA Code
         * @param {Verify2FADto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerify2FACode(body: Verify2FADto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthDto> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authControllerVerify2FACode(body, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Verify email address
         * @param {VerifyEmailDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerifyEmail(body: VerifyEmailDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StatusDto> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authControllerVerifyEmail(body, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Verify reset password token
         * @param {VerifyResetTokenDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerifyForgotPasswordToken(body: VerifyResetTokenDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EmailDto> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authControllerVerifyForgotPasswordToken(body, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Change password
         * @param {ChangePasswordDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerChangePassword(body: ChangePasswordDto, options?: any) {
            return AuthApiFp(configuration).authControllerChangePassword(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Disable 2FA Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerDisable2FACode(options?: any) {
            return AuthApiFp(configuration).authControllerDisable2FACode(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Enable 2FA Code
         * @param {Enable2FADto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerEnable2FACode(body: Enable2FADto, options?: any) {
            return AuthApiFp(configuration).authControllerEnable2FACode(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Request reset password
         * @param {EmailDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerForgotPassword(body: EmailDto, options?: any) {
            return AuthApiFp(configuration).authControllerForgotPassword(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogout(options?: any) {
            return AuthApiFp(configuration).authControllerLogout(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get new Access Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefresh(options?: any) {
            return AuthApiFp(configuration).authControllerRefresh(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Request 2FA Code
         * @param {Request2FADto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRequest2FACode(body: Request2FADto, options?: any) {
            return AuthApiFp(configuration).authControllerRequest2FACode(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Resend 2FA Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResendMfa(options?: any) {
            return AuthApiFp(configuration).authControllerResendMfa(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Resend verification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResendVerification(options?: any) {
            return AuthApiFp(configuration).authControllerResendVerification(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Reset password
         * @param {ResetPasswordDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResetPasswordByToken(body: ResetPasswordDto, options?: any) {
            return AuthApiFp(configuration).authControllerResetPasswordByToken(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Sign in via Email
         * @param {EmailSignInDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignInEmail(body: EmailSignInDto, options?: any) {
            return AuthApiFp(configuration).authControllerSignInEmail(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Sign in via Google
         * @param {GoogleSignUpDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignInGoogle(body: GoogleSignUpDto, options?: any) {
            return AuthApiFp(configuration).authControllerSignInGoogle(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Sign Up via Email
         * @param {EmailSignUpDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignUp(body: EmailSignUpDto, options?: any) {
            return AuthApiFp(configuration).authControllerSignUp(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Check username availability
         * @param {UsernameDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerUsernameAvailable(body: UsernameDto, options?: any) {
            return AuthApiFp(configuration).authControllerUsernameAvailable(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Verify 2FA Code
         * @param {Verify2FADto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerify2FACode(body: Verify2FADto, options?: any) {
            return AuthApiFp(configuration).authControllerVerify2FACode(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Verify email address
         * @param {VerifyEmailDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerifyEmail(body: VerifyEmailDto, options?: any) {
            return AuthApiFp(configuration).authControllerVerifyEmail(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Verify reset password token
         * @param {VerifyResetTokenDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerifyForgotPasswordToken(body: VerifyResetTokenDto, options?: any) {
            return AuthApiFp(configuration).authControllerVerifyForgotPasswordToken(body, options)(fetch, basePath);
        },
    };
};

/**
 * AuthApi - interface
 * @export
 * @interface AuthApi
 */
export interface AuthApiInterface {
    /**
     * 
     * @summary Change password
     * @param {ChangePasswordDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerChangePassword(body: ChangePasswordDto, options?: any): Promise<User>;

    /**
     * 
     * @summary Disable 2FA Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerDisable2FACode(options?: any): Promise<User>;

    /**
     * 
     * @summary Enable 2FA Code
     * @param {Enable2FADto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerEnable2FACode(body: Enable2FADto, options?: any): Promise<AuthDto>;

    /**
     * 
     * @summary Request reset password
     * @param {EmailDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerForgotPassword(body: EmailDto, options?: any): Promise<{}>;

    /**
     * 
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerLogout(options?: any): Promise<{}>;

    /**
     * 
     * @summary Get new Access Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerRefresh(options?: any): Promise<AuthDto>;

    /**
     * 
     * @summary Request 2FA Code
     * @param {Request2FADto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerRequest2FACode(body: Request2FADto, options?: any): Promise<StatusDto>;

    /**
     * 
     * @summary Resend 2FA Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerResendMfa(options?: any): Promise<StatusDto>;

    /**
     * 
     * @summary Resend verification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerResendVerification(options?: any): Promise<StatusDto>;

    /**
     * 
     * @summary Reset password
     * @param {ResetPasswordDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerResetPasswordByToken(body: ResetPasswordDto, options?: any): Promise<EmailDto>;

    /**
     * 
     * @summary Sign in via Email
     * @param {EmailSignInDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerSignInEmail(body: EmailSignInDto, options?: any): Promise<AuthDto>;

    /**
     * 
     * @summary Sign in via Google
     * @param {GoogleSignUpDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerSignInGoogle(body: GoogleSignUpDto, options?: any): Promise<GoogleAuthDto>;

    /**
     * 
     * @summary Sign Up via Email
     * @param {EmailSignUpDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerSignUp(body: EmailSignUpDto, options?: any): Promise<AuthDto>;

    /**
     * 
     * @summary Check username availability
     * @param {UsernameDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerUsernameAvailable(body: UsernameDto, options?: any): Promise<AvailableDto>;

    /**
     * 
     * @summary Verify 2FA Code
     * @param {Verify2FADto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerVerify2FACode(body: Verify2FADto, options?: any): Promise<AuthDto>;

    /**
     * 
     * @summary Verify email address
     * @param {VerifyEmailDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerVerifyEmail(body: VerifyEmailDto, options?: any): Promise<StatusDto>;

    /**
     * 
     * @summary Verify reset password token
     * @param {VerifyResetTokenDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApiInterface
     */
    authControllerVerifyForgotPasswordToken(body: VerifyResetTokenDto, options?: any): Promise<EmailDto>;

}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI implements AuthApiInterface {
    /**
     * 
     * @summary Change password
     * @param {ChangePasswordDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerChangePassword(body: ChangePasswordDto, options?: any) {
        return AuthApiFp(this.configuration).authControllerChangePassword(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Disable 2FA Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerDisable2FACode(options?: any) {
        return AuthApiFp(this.configuration).authControllerDisable2FACode(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Enable 2FA Code
     * @param {Enable2FADto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerEnable2FACode(body: Enable2FADto, options?: any) {
        return AuthApiFp(this.configuration).authControllerEnable2FACode(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Request reset password
     * @param {EmailDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerForgotPassword(body: EmailDto, options?: any) {
        return AuthApiFp(this.configuration).authControllerForgotPassword(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerLogout(options?: any) {
        return AuthApiFp(this.configuration).authControllerLogout(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get new Access Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerRefresh(options?: any) {
        return AuthApiFp(this.configuration).authControllerRefresh(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Request 2FA Code
     * @param {Request2FADto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerRequest2FACode(body: Request2FADto, options?: any) {
        return AuthApiFp(this.configuration).authControllerRequest2FACode(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Resend 2FA Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerResendMfa(options?: any) {
        return AuthApiFp(this.configuration).authControllerResendMfa(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Resend verification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerResendVerification(options?: any) {
        return AuthApiFp(this.configuration).authControllerResendVerification(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Reset password
     * @param {ResetPasswordDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerResetPasswordByToken(body: ResetPasswordDto, options?: any) {
        return AuthApiFp(this.configuration).authControllerResetPasswordByToken(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Sign in via Email
     * @param {EmailSignInDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerSignInEmail(body: EmailSignInDto, options?: any) {
        return AuthApiFp(this.configuration).authControllerSignInEmail(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Sign in via Google
     * @param {GoogleSignUpDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerSignInGoogle(body: GoogleSignUpDto, options?: any) {
        return AuthApiFp(this.configuration).authControllerSignInGoogle(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Sign Up via Email
     * @param {EmailSignUpDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerSignUp(body: EmailSignUpDto, options?: any) {
        return AuthApiFp(this.configuration).authControllerSignUp(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Check username availability
     * @param {UsernameDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerUsernameAvailable(body: UsernameDto, options?: any) {
        return AuthApiFp(this.configuration).authControllerUsernameAvailable(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Verify 2FA Code
     * @param {Verify2FADto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerVerify2FACode(body: Verify2FADto, options?: any) {
        return AuthApiFp(this.configuration).authControllerVerify2FACode(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Verify email address
     * @param {VerifyEmailDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerVerifyEmail(body: VerifyEmailDto, options?: any) {
        return AuthApiFp(this.configuration).authControllerVerifyEmail(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Verify reset password token
     * @param {VerifyResetTokenDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerVerifyForgotPasswordToken(body: VerifyResetTokenDto, options?: any) {
        return AuthApiFp(this.configuration).authControllerVerifyForgotPasswordToken(body, options)(this.fetch, this.basePath);
    }

}
/**
 * CollectionApi - fetch parameter creator
 * @export
 */
export const CollectionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all collections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionControllerGetAllCollections(options: any = {}): FetchArgs {
            const localVarPath = `/v1/collection`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get collection
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionControllerGetCollection(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling collectionControllerGetCollection.');
            }
            const localVarPath = `/v1/collection/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get featured collections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionControllerGetFeaturedCollections(options: any = {}): FetchArgs {
            const localVarPath = `/v1/collection/featured`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CollectionApi - functional programming interface
 * @export
 */
export const CollectionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all collections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionControllerGetAllCollections(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ItemCollection>> {
            const localVarFetchArgs = CollectionApiFetchParamCreator(configuration).collectionControllerGetAllCollections(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get collection
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionControllerGetCollection(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemCollection> {
            const localVarFetchArgs = CollectionApiFetchParamCreator(configuration).collectionControllerGetCollection(id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get featured collections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionControllerGetFeaturedCollections(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ItemCollection>> {
            const localVarFetchArgs = CollectionApiFetchParamCreator(configuration).collectionControllerGetFeaturedCollections(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CollectionApi - factory interface
 * @export
 */
export const CollectionApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get all collections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionControllerGetAllCollections(options?: any) {
            return CollectionApiFp(configuration).collectionControllerGetAllCollections(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get collection
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionControllerGetCollection(id: string, options?: any) {
            return CollectionApiFp(configuration).collectionControllerGetCollection(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get featured collections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionControllerGetFeaturedCollections(options?: any) {
            return CollectionApiFp(configuration).collectionControllerGetFeaturedCollections(options)(fetch, basePath);
        },
    };
};

/**
 * CollectionApi - interface
 * @export
 * @interface CollectionApi
 */
export interface CollectionApiInterface {
    /**
     * 
     * @summary Get all collections
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApiInterface
     */
    collectionControllerGetAllCollections(options?: any): Promise<Array<ItemCollection>>;

    /**
     * 
     * @summary Get collection
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApiInterface
     */
    collectionControllerGetCollection(id: string, options?: any): Promise<ItemCollection>;

    /**
     * 
     * @summary Get featured collections
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApiInterface
     */
    collectionControllerGetFeaturedCollections(options?: any): Promise<Array<ItemCollection>>;

}

/**
 * CollectionApi - object-oriented interface
 * @export
 * @class CollectionApi
 * @extends {BaseAPI}
 */
export class CollectionApi extends BaseAPI implements CollectionApiInterface {
    /**
     * 
     * @summary Get all collections
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public collectionControllerGetAllCollections(options?: any) {
        return CollectionApiFp(this.configuration).collectionControllerGetAllCollections(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get collection
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public collectionControllerGetCollection(id: string, options?: any) {
        return CollectionApiFp(this.configuration).collectionControllerGetCollection(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get featured collections
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public collectionControllerGetFeaturedCollections(options?: any) {
        return CollectionApiFp(this.configuration).collectionControllerGetFeaturedCollections(options)(this.fetch, this.basePath);
    }

}
/**
 * IdentityApi - fetch parameter creator
 * @export
 */
export const IdentityApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get client secret for a identity verification session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityControllerStartVerification(options: any = {}): FetchArgs {
            const localVarPath = `/v1/identity/start-verification`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit identity verification session
         * @param {string} verification_session_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityControllerSubmitVerification(verification_session_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'verification_session_id' is not null or undefined
            if (verification_session_id === null || verification_session_id === undefined) {
                throw new RequiredError('verification_session_id','Required parameter verification_session_id was null or undefined when calling identityControllerSubmitVerification.');
            }
            const localVarPath = `/v1/identity/submit-verification/{verification_session_id}`
                .replace(`{${'verification_session_id'}}`, encodeURIComponent(String(verification_session_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IdentityApi - functional programming interface
 * @export
 */
export const IdentityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get client secret for a identity verification session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityControllerStartVerification(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClientSecretDto> {
            const localVarFetchArgs = IdentityApiFetchParamCreator(configuration).identityControllerStartVerification(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Submit identity verification session
         * @param {string} verification_session_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityControllerSubmitVerification(verification_session_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<User> {
            const localVarFetchArgs = IdentityApiFetchParamCreator(configuration).identityControllerSubmitVerification(verification_session_id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * IdentityApi - factory interface
 * @export
 */
export const IdentityApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get client secret for a identity verification session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityControllerStartVerification(options?: any) {
            return IdentityApiFp(configuration).identityControllerStartVerification(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Submit identity verification session
         * @param {string} verification_session_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityControllerSubmitVerification(verification_session_id: string, options?: any) {
            return IdentityApiFp(configuration).identityControllerSubmitVerification(verification_session_id, options)(fetch, basePath);
        },
    };
};

/**
 * IdentityApi - interface
 * @export
 * @interface IdentityApi
 */
export interface IdentityApiInterface {
    /**
     * 
     * @summary Get client secret for a identity verification session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityApiInterface
     */
    identityControllerStartVerification(options?: any): Promise<ClientSecretDto>;

    /**
     * 
     * @summary Submit identity verification session
     * @param {string} verification_session_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityApiInterface
     */
    identityControllerSubmitVerification(verification_session_id: string, options?: any): Promise<User>;

}

/**
 * IdentityApi - object-oriented interface
 * @export
 * @class IdentityApi
 * @extends {BaseAPI}
 */
export class IdentityApi extends BaseAPI implements IdentityApiInterface {
    /**
     * 
     * @summary Get client secret for a identity verification session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityApi
     */
    public identityControllerStartVerification(options?: any) {
        return IdentityApiFp(this.configuration).identityControllerStartVerification(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Submit identity verification session
     * @param {string} verification_session_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityApi
     */
    public identityControllerSubmitVerification(verification_session_id: string, options?: any) {
        return IdentityApiFp(this.configuration).identityControllerSubmitVerification(verification_session_id, options)(this.fetch, this.basePath);
    }

}
/**
 * InvoiceApi - fetch parameter creator
 * @export
 */
export const InvoiceApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Payment intent for estimate with existing method
         * @param {PaymentDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerClientSecretForEstimate(body: PaymentDto, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling invoiceControllerClientSecretForEstimate.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling invoiceControllerClientSecretForEstimate.');
            }
            const localVarPath = `/v1/invoice/estimate/{id}/payment_intent/existing`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'PaymentDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Payment intent for estimate
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerClientSecretForEstimateNew(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling invoiceControllerClientSecretForEstimateNew.');
            }
            const localVarPath = `/v1/invoice/estimate/{id}/payment_intent`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Payment intent for estimate (express)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerClientSecretForExpressEstimate(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling invoiceControllerClientSecretForExpressEstimate.');
            }
            const localVarPath = `/v1/invoice/estimate/{id}/payment_intent/express`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get invoice
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerGetInvoice(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling invoiceControllerGetInvoice.');
            }
            const localVarPath = `/v1/invoice/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get invoices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerGetInvoices(options: any = {}): FetchArgs {
            const localVarPath = `/v1/invoice/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Payment intent for invoice
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerGetPaymentIntentForInvoice(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling invoiceControllerGetPaymentIntentForInvoice.');
            }
            const localVarPath = `/v1/invoice/{id}/payment_intent`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pay invoice
         * @param {PaymentIntentDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerPayInvoice(body: PaymentIntentDto, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling invoiceControllerPayInvoice.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling invoiceControllerPayInvoice.');
            }
            const localVarPath = `/v1/invoice/{id}/pay`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'PaymentIntentDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Payment intent for invoice with existing method
         * @param {PaymentDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerPayInvoiceExisting(body: PaymentDto, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling invoiceControllerPayInvoiceExisting.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling invoiceControllerPayInvoiceExisting.');
            }
            const localVarPath = `/v1/invoice/{id}/payment_intent/existing`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'PaymentDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update delivery method
         * @param {UpdateDeliveryMethodDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerUpdateDeliveryMethod(body: UpdateDeliveryMethodDto, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling invoiceControllerUpdateDeliveryMethod.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling invoiceControllerUpdateDeliveryMethod.');
            }
            const localVarPath = `/v1/invoice/{id}/delivery-method`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'UpdateDeliveryMethodDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceApi - functional programming interface
 * @export
 */
export const InvoiceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Payment intent for estimate with existing method
         * @param {PaymentDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerClientSecretForEstimate(body: PaymentDto, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClientSecretDto> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceControllerClientSecretForEstimate(body, id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Payment intent for estimate
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerClientSecretForEstimateNew(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClientSecretDto> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceControllerClientSecretForEstimateNew(id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Payment intent for estimate (express)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerClientSecretForExpressEstimate(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClientSecretDto> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceControllerClientSecretForExpressEstimate(id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get invoice
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerGetInvoice(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Invoice> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceControllerGetInvoice(id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get invoices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerGetInvoices(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Invoice>> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceControllerGetInvoices(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Payment intent for invoice
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerGetPaymentIntentForInvoice(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClientSecretDto> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceControllerGetPaymentIntentForInvoice(id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Pay invoice
         * @param {PaymentIntentDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerPayInvoice(body: PaymentIntentDto, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Invoice> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceControllerPayInvoice(body, id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Payment intent for invoice with existing method
         * @param {PaymentDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerPayInvoiceExisting(body: PaymentDto, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClientSecretDto> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceControllerPayInvoiceExisting(body, id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update delivery method
         * @param {UpdateDeliveryMethodDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerUpdateDeliveryMethod(body: UpdateDeliveryMethodDto, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Invoice> {
            const localVarFetchArgs = InvoiceApiFetchParamCreator(configuration).invoiceControllerUpdateDeliveryMethod(body, id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * InvoiceApi - factory interface
 * @export
 */
export const InvoiceApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get Payment intent for estimate with existing method
         * @param {PaymentDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerClientSecretForEstimate(body: PaymentDto, id: string, options?: any) {
            return InvoiceApiFp(configuration).invoiceControllerClientSecretForEstimate(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Payment intent for estimate
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerClientSecretForEstimateNew(id: string, options?: any) {
            return InvoiceApiFp(configuration).invoiceControllerClientSecretForEstimateNew(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Payment intent for estimate (express)
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerClientSecretForExpressEstimate(id: string, options?: any) {
            return InvoiceApiFp(configuration).invoiceControllerClientSecretForExpressEstimate(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get invoice
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerGetInvoice(id: string, options?: any) {
            return InvoiceApiFp(configuration).invoiceControllerGetInvoice(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get invoices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerGetInvoices(options?: any) {
            return InvoiceApiFp(configuration).invoiceControllerGetInvoices(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Payment intent for invoice
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerGetPaymentIntentForInvoice(id: string, options?: any) {
            return InvoiceApiFp(configuration).invoiceControllerGetPaymentIntentForInvoice(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Pay invoice
         * @param {PaymentIntentDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerPayInvoice(body: PaymentIntentDto, id: string, options?: any) {
            return InvoiceApiFp(configuration).invoiceControllerPayInvoice(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Payment intent for invoice with existing method
         * @param {PaymentDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerPayInvoiceExisting(body: PaymentDto, id: string, options?: any) {
            return InvoiceApiFp(configuration).invoiceControllerPayInvoiceExisting(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update delivery method
         * @param {UpdateDeliveryMethodDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceControllerUpdateDeliveryMethod(body: UpdateDeliveryMethodDto, id: string, options?: any) {
            return InvoiceApiFp(configuration).invoiceControllerUpdateDeliveryMethod(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * InvoiceApi - interface
 * @export
 * @interface InvoiceApi
 */
export interface InvoiceApiInterface {
    /**
     * 
     * @summary Get Payment intent for estimate with existing method
     * @param {PaymentDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApiInterface
     */
    invoiceControllerClientSecretForEstimate(body: PaymentDto, id: string, options?: any): Promise<ClientSecretDto>;

    /**
     * 
     * @summary Get Payment intent for estimate
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApiInterface
     */
    invoiceControllerClientSecretForEstimateNew(id: string, options?: any): Promise<ClientSecretDto>;

    /**
     * 
     * @summary Get Payment intent for estimate (express)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApiInterface
     */
    invoiceControllerClientSecretForExpressEstimate(id: string, options?: any): Promise<ClientSecretDto>;

    /**
     * 
     * @summary Get invoice
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApiInterface
     */
    invoiceControllerGetInvoice(id: string, options?: any): Promise<Invoice>;

    /**
     * 
     * @summary Get invoices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApiInterface
     */
    invoiceControllerGetInvoices(options?: any): Promise<Array<Invoice>>;

    /**
     * 
     * @summary Get Payment intent for invoice
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApiInterface
     */
    invoiceControllerGetPaymentIntentForInvoice(id: string, options?: any): Promise<ClientSecretDto>;

    /**
     * 
     * @summary Pay invoice
     * @param {PaymentIntentDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApiInterface
     */
    invoiceControllerPayInvoice(body: PaymentIntentDto, id: string, options?: any): Promise<Invoice>;

    /**
     * 
     * @summary Get Payment intent for invoice with existing method
     * @param {PaymentDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApiInterface
     */
    invoiceControllerPayInvoiceExisting(body: PaymentDto, id: string, options?: any): Promise<ClientSecretDto>;

    /**
     * 
     * @summary Update delivery method
     * @param {UpdateDeliveryMethodDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApiInterface
     */
    invoiceControllerUpdateDeliveryMethod(body: UpdateDeliveryMethodDto, id: string, options?: any): Promise<Invoice>;

}

/**
 * InvoiceApi - object-oriented interface
 * @export
 * @class InvoiceApi
 * @extends {BaseAPI}
 */
export class InvoiceApi extends BaseAPI implements InvoiceApiInterface {
    /**
     * 
     * @summary Get Payment intent for estimate with existing method
     * @param {PaymentDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceControllerClientSecretForEstimate(body: PaymentDto, id: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceControllerClientSecretForEstimate(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Payment intent for estimate
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceControllerClientSecretForEstimateNew(id: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceControllerClientSecretForEstimateNew(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Payment intent for estimate (express)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceControllerClientSecretForExpressEstimate(id: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceControllerClientSecretForExpressEstimate(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get invoice
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceControllerGetInvoice(id: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceControllerGetInvoice(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get invoices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceControllerGetInvoices(options?: any) {
        return InvoiceApiFp(this.configuration).invoiceControllerGetInvoices(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Payment intent for invoice
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceControllerGetPaymentIntentForInvoice(id: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceControllerGetPaymentIntentForInvoice(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Pay invoice
     * @param {PaymentIntentDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceControllerPayInvoice(body: PaymentIntentDto, id: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceControllerPayInvoice(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Payment intent for invoice with existing method
     * @param {PaymentDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceControllerPayInvoiceExisting(body: PaymentDto, id: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceControllerPayInvoiceExisting(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update delivery method
     * @param {UpdateDeliveryMethodDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public invoiceControllerUpdateDeliveryMethod(body: UpdateDeliveryMethodDto, id: string, options?: any) {
        return InvoiceApiFp(this.configuration).invoiceControllerUpdateDeliveryMethod(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * ItemApi - fetch parameter creator
 * @export
 */
export const ItemApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get authentication data
         * @param {string} qrcode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerGetAuthenticationData(qrcode: string, options: any = {}): FetchArgs {
            // verify required parameter 'qrcode' is not null or undefined
            if (qrcode === null || qrcode === undefined) {
                throw new RequiredError('qrcode','Required parameter qrcode was null or undefined when calling itemControllerGetAuthenticationData.');
            }
            const localVarPath = `/v1/item/authentication/{qrcode}`
                .replace(`{${'qrcode'}}`, encodeURIComponent(String(qrcode)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get items in bulk
         * @param {Array<string>} id The id(s)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerGetBulk(id: Array<string>, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling itemControllerGetBulk.');
            }
            const localVarPath = `/v1/item/bulk`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            if (id) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get item
         * @param {string} id 
         * @param {boolean} [track_view] Track Entity View
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerGetById(id: string, track_view?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling itemControllerGetById.');
            }
            const localVarPath = `/v1/item/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            if (track_view !== undefined) {
                localVarQueryParameter['track_view'] = track_view;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get item by slug
         * @param {string} slug 
         * @param {boolean} [track_view] Track Entity View
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerGetBySlug(slug: string, track_view?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling itemControllerGetBySlug.');
            }
            const localVarPath = `/v1/item/slug/{slug}`
                .replace(`{${'slug'}}`, encodeURIComponent(String(slug)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            if (track_view !== undefined) {
                localVarQueryParameter['track_view'] = track_view;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current bidding items
         * @param {number} [offset] Result cursor (amount of results to skip)
         * @param {number} [limit] Limit results
         * @param {boolean} [active] Filter active
         * @param {string} [sort_by] 
         * @param {string} [filter_by] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerGetCurrentBiddingItems(offset?: number, limit?: number, active?: boolean, sort_by?: string, filter_by?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/item/bidding`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            if (filter_by !== undefined) {
                localVarQueryParameter['filter_by'] = filter_by;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get owning items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerGetOwningItems(options: any = {}): FetchArgs {
            const localVarPath = `/v1/item/owning`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get recently viewed items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerGetRecentlyViewedItems(options: any = {}): FetchArgs {
            const localVarPath = `/v1/item/recently-viewed`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get related items
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerGetRelatedById(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling itemControllerGetRelatedById.');
            }
            const localVarPath = `/v1/item/{id}/related`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Link item by qr code
         * @param {string} qrcode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerLinkItemByQrCode(qrcode: string, options: any = {}): FetchArgs {
            // verify required parameter 'qrcode' is not null or undefined
            if (qrcode === null || qrcode === undefined) {
                throw new RequiredError('qrcode','Required parameter qrcode was null or undefined when calling itemControllerLinkItemByQrCode.');
            }
            const localVarPath = `/v1/item/link/{qrcode}`
                .replace(`{${'qrcode'}}`, encodeURIComponent(String(qrcode)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Track Item View
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerTrackItemView(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling itemControllerTrackItemView.');
            }
            const localVarPath = `/v1/item/{id}/view`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update min offer amount
         * @param {UpdateMinOfferDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerUpdateMinOfferAmount(body: UpdateMinOfferDto, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling itemControllerUpdateMinOfferAmount.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling itemControllerUpdateMinOfferAmount.');
            }
            const localVarPath = `/v1/item/{id}/min-offer`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'UpdateMinOfferDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemApi - functional programming interface
 * @export
 */
export const ItemApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get authentication data
         * @param {string} qrcode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerGetAuthenticationData(qrcode: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Authentication> {
            const localVarFetchArgs = ItemApiFetchParamCreator(configuration).itemControllerGetAuthenticationData(qrcode, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get items in bulk
         * @param {Array<string>} id The id(s)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerGetBulk(id: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Item>> {
            const localVarFetchArgs = ItemApiFetchParamCreator(configuration).itemControllerGetBulk(id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get item
         * @param {string} id 
         * @param {boolean} [track_view] Track Entity View
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerGetById(id: string, track_view?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Item> {
            const localVarFetchArgs = ItemApiFetchParamCreator(configuration).itemControllerGetById(id, track_view, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get item by slug
         * @param {string} slug 
         * @param {boolean} [track_view] Track Entity View
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerGetBySlug(slug: string, track_view?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Item> {
            const localVarFetchArgs = ItemApiFetchParamCreator(configuration).itemControllerGetBySlug(slug, track_view, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get current bidding items
         * @param {number} [offset] Result cursor (amount of results to skip)
         * @param {number} [limit] Limit results
         * @param {boolean} [active] Filter active
         * @param {string} [sort_by] 
         * @param {string} [filter_by] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerGetCurrentBiddingItems(offset?: number, limit?: number, active?: boolean, sort_by?: string, filter_by?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2001> {
            const localVarFetchArgs = ItemApiFetchParamCreator(configuration).itemControllerGetCurrentBiddingItems(offset, limit, active, sort_by, filter_by, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get owning items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerGetOwningItems(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Item>> {
            const localVarFetchArgs = ItemApiFetchParamCreator(configuration).itemControllerGetOwningItems(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get recently viewed items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerGetRecentlyViewedItems(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Item>> {
            const localVarFetchArgs = ItemApiFetchParamCreator(configuration).itemControllerGetRecentlyViewedItems(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get related items
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerGetRelatedById(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Item>> {
            const localVarFetchArgs = ItemApiFetchParamCreator(configuration).itemControllerGetRelatedById(id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Link item by qr code
         * @param {string} qrcode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerLinkItemByQrCode(qrcode: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Item> {
            const localVarFetchArgs = ItemApiFetchParamCreator(configuration).itemControllerLinkItemByQrCode(qrcode, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Track Item View
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerTrackItemView(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StatusDto> {
            const localVarFetchArgs = ItemApiFetchParamCreator(configuration).itemControllerTrackItemView(id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update min offer amount
         * @param {UpdateMinOfferDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerUpdateMinOfferAmount(body: UpdateMinOfferDto, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Item> {
            const localVarFetchArgs = ItemApiFetchParamCreator(configuration).itemControllerUpdateMinOfferAmount(body, id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ItemApi - factory interface
 * @export
 */
export const ItemApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get authentication data
         * @param {string} qrcode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerGetAuthenticationData(qrcode: string, options?: any) {
            return ItemApiFp(configuration).itemControllerGetAuthenticationData(qrcode, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get items in bulk
         * @param {Array<string>} id The id(s)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerGetBulk(id: Array<string>, options?: any) {
            return ItemApiFp(configuration).itemControllerGetBulk(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get item
         * @param {string} id 
         * @param {boolean} [track_view] Track Entity View
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerGetById(id: string, track_view?: boolean, options?: any) {
            return ItemApiFp(configuration).itemControllerGetById(id, track_view, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get item by slug
         * @param {string} slug 
         * @param {boolean} [track_view] Track Entity View
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerGetBySlug(slug: string, track_view?: boolean, options?: any) {
            return ItemApiFp(configuration).itemControllerGetBySlug(slug, track_view, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get current bidding items
         * @param {number} [offset] Result cursor (amount of results to skip)
         * @param {number} [limit] Limit results
         * @param {boolean} [active] Filter active
         * @param {string} [sort_by] 
         * @param {string} [filter_by] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerGetCurrentBiddingItems(offset?: number, limit?: number, active?: boolean, sort_by?: string, filter_by?: string, options?: any) {
            return ItemApiFp(configuration).itemControllerGetCurrentBiddingItems(offset, limit, active, sort_by, filter_by, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get owning items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerGetOwningItems(options?: any) {
            return ItemApiFp(configuration).itemControllerGetOwningItems(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get recently viewed items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerGetRecentlyViewedItems(options?: any) {
            return ItemApiFp(configuration).itemControllerGetRecentlyViewedItems(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get related items
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerGetRelatedById(id: string, options?: any) {
            return ItemApiFp(configuration).itemControllerGetRelatedById(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Link item by qr code
         * @param {string} qrcode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerLinkItemByQrCode(qrcode: string, options?: any) {
            return ItemApiFp(configuration).itemControllerLinkItemByQrCode(qrcode, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Track Item View
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerTrackItemView(id: string, options?: any) {
            return ItemApiFp(configuration).itemControllerTrackItemView(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update min offer amount
         * @param {UpdateMinOfferDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemControllerUpdateMinOfferAmount(body: UpdateMinOfferDto, id: string, options?: any) {
            return ItemApiFp(configuration).itemControllerUpdateMinOfferAmount(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * ItemApi - interface
 * @export
 * @interface ItemApi
 */
export interface ItemApiInterface {
    /**
     * 
     * @summary Get authentication data
     * @param {string} qrcode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApiInterface
     */
    itemControllerGetAuthenticationData(qrcode: string, options?: any): Promise<Authentication>;

    /**
     * 
     * @summary Get items in bulk
     * @param {Array<string>} id The id(s)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApiInterface
     */
    itemControllerGetBulk(id: Array<string>, options?: any): Promise<Array<Item>>;

    /**
     * 
     * @summary Get item
     * @param {string} id 
     * @param {boolean} [track_view] Track Entity View
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApiInterface
     */
    itemControllerGetById(id: string, track_view?: boolean, options?: any): Promise<Item>;

    /**
     * 
     * @summary Get item by slug
     * @param {string} slug 
     * @param {boolean} [track_view] Track Entity View
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApiInterface
     */
    itemControllerGetBySlug(slug: string, track_view?: boolean, options?: any): Promise<Item>;

    /**
     * 
     * @summary Get current bidding items
     * @param {number} [offset] Result cursor (amount of results to skip)
     * @param {number} [limit] Limit results
     * @param {boolean} [active] Filter active
     * @param {string} [sort_by] 
     * @param {string} [filter_by] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApiInterface
     */
    itemControllerGetCurrentBiddingItems(offset?: number, limit?: number, active?: boolean, sort_by?: string, filter_by?: string, options?: any): Promise<InlineResponse2001>;

    /**
     * 
     * @summary Get owning items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApiInterface
     */
    itemControllerGetOwningItems(options?: any): Promise<Array<Item>>;

    /**
     * 
     * @summary Get recently viewed items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApiInterface
     */
    itemControllerGetRecentlyViewedItems(options?: any): Promise<Array<Item>>;

    /**
     * 
     * @summary Get related items
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApiInterface
     */
    itemControllerGetRelatedById(id: string, options?: any): Promise<Array<Item>>;

    /**
     * 
     * @summary Link item by qr code
     * @param {string} qrcode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApiInterface
     */
    itemControllerLinkItemByQrCode(qrcode: string, options?: any): Promise<Item>;

    /**
     * 
     * @summary Track Item View
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApiInterface
     */
    itemControllerTrackItemView(id: string, options?: any): Promise<StatusDto>;

    /**
     * 
     * @summary Update min offer amount
     * @param {UpdateMinOfferDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApiInterface
     */
    itemControllerUpdateMinOfferAmount(body: UpdateMinOfferDto, id: string, options?: any): Promise<Item>;

}

/**
 * ItemApi - object-oriented interface
 * @export
 * @class ItemApi
 * @extends {BaseAPI}
 */
export class ItemApi extends BaseAPI implements ItemApiInterface {
    /**
     * 
     * @summary Get authentication data
     * @param {string} qrcode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemControllerGetAuthenticationData(qrcode: string, options?: any) {
        return ItemApiFp(this.configuration).itemControllerGetAuthenticationData(qrcode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get items in bulk
     * @param {Array<string>} id The id(s)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemControllerGetBulk(id: Array<string>, options?: any) {
        return ItemApiFp(this.configuration).itemControllerGetBulk(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get item
     * @param {string} id 
     * @param {boolean} [track_view] Track Entity View
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemControllerGetById(id: string, track_view?: boolean, options?: any) {
        return ItemApiFp(this.configuration).itemControllerGetById(id, track_view, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get item by slug
     * @param {string} slug 
     * @param {boolean} [track_view] Track Entity View
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemControllerGetBySlug(slug: string, track_view?: boolean, options?: any) {
        return ItemApiFp(this.configuration).itemControllerGetBySlug(slug, track_view, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get current bidding items
     * @param {number} [offset] Result cursor (amount of results to skip)
     * @param {number} [limit] Limit results
     * @param {boolean} [active] Filter active
     * @param {string} [sort_by] 
     * @param {string} [filter_by] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemControllerGetCurrentBiddingItems(offset?: number, limit?: number, active?: boolean, sort_by?: string, filter_by?: string, options?: any) {
        return ItemApiFp(this.configuration).itemControllerGetCurrentBiddingItems(offset, limit, active, sort_by, filter_by, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get owning items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemControllerGetOwningItems(options?: any) {
        return ItemApiFp(this.configuration).itemControllerGetOwningItems(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get recently viewed items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemControllerGetRecentlyViewedItems(options?: any) {
        return ItemApiFp(this.configuration).itemControllerGetRecentlyViewedItems(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get related items
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemControllerGetRelatedById(id: string, options?: any) {
        return ItemApiFp(this.configuration).itemControllerGetRelatedById(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Link item by qr code
     * @param {string} qrcode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemControllerLinkItemByQrCode(qrcode: string, options?: any) {
        return ItemApiFp(this.configuration).itemControllerLinkItemByQrCode(qrcode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Track Item View
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemControllerTrackItemView(id: string, options?: any) {
        return ItemApiFp(this.configuration).itemControllerTrackItemView(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update min offer amount
     * @param {UpdateMinOfferDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public itemControllerUpdateMinOfferAmount(body: UpdateMinOfferDto, id: string, options?: any) {
        return ItemApiFp(this.configuration).itemControllerUpdateMinOfferAmount(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * MetaApi - fetch parameter creator
 * @export
 */
export const MetaApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get artist by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerArtistById(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling metaControllerArtistById.');
            }
            const localVarPath = `/v1/meta/artists/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get artist by slug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerArtistBySlug(slug: string, options: any = {}): FetchArgs {
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling metaControllerArtistBySlug.');
            }
            const localVarPath = `/v1/meta/artists/slug/{slug}`
                .replace(`{${'slug'}}`, encodeURIComponent(String(slug)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all artists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerArtists(options: any = {}): FetchArgs {
            const localVarPath = `/v1/meta/artists`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerCategories(options: any = {}): FetchArgs {
            const localVarPath = `/v1/meta/categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get category by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerCategoryById(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling metaControllerCategoryById.');
            }
            const localVarPath = `/v1/meta/categories/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get category by slug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerCategoryBySlug(slug: string, options: any = {}): FetchArgs {
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling metaControllerCategoryBySlug.');
            }
            const localVarPath = `/v1/meta/categories/slug/{slug}`
                .replace(`{${'slug'}}`, encodeURIComponent(String(slug)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get condition by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerConditionById(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling metaControllerConditionById.');
            }
            const localVarPath = `/v1/meta/conditions/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all conditions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerConditions(options: any = {}): FetchArgs {
            const localVarPath = `/v1/meta/conditions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get genre by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerGenreById(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling metaControllerGenreById.');
            }
            const localVarPath = `/v1/meta/genres/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get genre by slug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerGenreBySlug(slug: string, options: any = {}): FetchArgs {
            // verify required parameter 'slug' is not null or undefined
            if (slug === null || slug === undefined) {
                throw new RequiredError('slug','Required parameter slug was null or undefined when calling metaControllerGenreBySlug.');
            }
            const localVarPath = `/v1/meta/genres/slug/{slug}`
                .replace(`{${'slug'}}`, encodeURIComponent(String(slug)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all genres
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerGenres(options: any = {}): FetchArgs {
            const localVarPath = `/v1/meta/genres`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all partners
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerPartners(options: any = {}): FetchArgs {
            const localVarPath = `/v1/meta/partners`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetaApi - functional programming interface
 * @export
 */
export const MetaApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get artist by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerArtistById(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemArtist> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).metaControllerArtistById(id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get artist by slug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerArtistBySlug(slug: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemArtist> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).metaControllerArtistBySlug(slug, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all artists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerArtists(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ItemArtist>> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).metaControllerArtists(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerCategories(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ItemCategory>> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).metaControllerCategories(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get category by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerCategoryById(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemCategory> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).metaControllerCategoryById(id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get category by slug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerCategoryBySlug(slug: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemCategory> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).metaControllerCategoryBySlug(slug, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get condition by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerConditionById(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemCondition> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).metaControllerConditionById(id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all conditions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerConditions(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ItemCondition>> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).metaControllerConditions(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get genre by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerGenreById(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemGenre> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).metaControllerGenreById(id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get genre by slug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerGenreBySlug(slug: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemGenre> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).metaControllerGenreBySlug(slug, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all genres
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerGenres(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ItemGenre>> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).metaControllerGenres(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all partners
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerPartners(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ItemPartner>> {
            const localVarFetchArgs = MetaApiFetchParamCreator(configuration).metaControllerPartners(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * MetaApi - factory interface
 * @export
 */
export const MetaApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get artist by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerArtistById(id: string, options?: any) {
            return MetaApiFp(configuration).metaControllerArtistById(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get artist by slug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerArtistBySlug(slug: string, options?: any) {
            return MetaApiFp(configuration).metaControllerArtistBySlug(slug, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all artists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerArtists(options?: any) {
            return MetaApiFp(configuration).metaControllerArtists(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerCategories(options?: any) {
            return MetaApiFp(configuration).metaControllerCategories(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get category by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerCategoryById(id: string, options?: any) {
            return MetaApiFp(configuration).metaControllerCategoryById(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get category by slug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerCategoryBySlug(slug: string, options?: any) {
            return MetaApiFp(configuration).metaControllerCategoryBySlug(slug, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get condition by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerConditionById(id: string, options?: any) {
            return MetaApiFp(configuration).metaControllerConditionById(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all conditions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerConditions(options?: any) {
            return MetaApiFp(configuration).metaControllerConditions(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get genre by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerGenreById(id: string, options?: any) {
            return MetaApiFp(configuration).metaControllerGenreById(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get genre by slug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerGenreBySlug(slug: string, options?: any) {
            return MetaApiFp(configuration).metaControllerGenreBySlug(slug, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all genres
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerGenres(options?: any) {
            return MetaApiFp(configuration).metaControllerGenres(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all partners
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metaControllerPartners(options?: any) {
            return MetaApiFp(configuration).metaControllerPartners(options)(fetch, basePath);
        },
    };
};

/**
 * MetaApi - interface
 * @export
 * @interface MetaApi
 */
export interface MetaApiInterface {
    /**
     * 
     * @summary Get artist by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApiInterface
     */
    metaControllerArtistById(id: string, options?: any): Promise<ItemArtist>;

    /**
     * 
     * @summary Get artist by slug
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApiInterface
     */
    metaControllerArtistBySlug(slug: string, options?: any): Promise<ItemArtist>;

    /**
     * 
     * @summary Get all artists
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApiInterface
     */
    metaControllerArtists(options?: any): Promise<Array<ItemArtist>>;

    /**
     * 
     * @summary Get all categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApiInterface
     */
    metaControllerCategories(options?: any): Promise<Array<ItemCategory>>;

    /**
     * 
     * @summary Get category by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApiInterface
     */
    metaControllerCategoryById(id: string, options?: any): Promise<ItemCategory>;

    /**
     * 
     * @summary Get category by slug
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApiInterface
     */
    metaControllerCategoryBySlug(slug: string, options?: any): Promise<ItemCategory>;

    /**
     * 
     * @summary Get condition by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApiInterface
     */
    metaControllerConditionById(id: string, options?: any): Promise<ItemCondition>;

    /**
     * 
     * @summary Get all conditions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApiInterface
     */
    metaControllerConditions(options?: any): Promise<Array<ItemCondition>>;

    /**
     * 
     * @summary Get genre by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApiInterface
     */
    metaControllerGenreById(id: string, options?: any): Promise<ItemGenre>;

    /**
     * 
     * @summary Get genre by slug
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApiInterface
     */
    metaControllerGenreBySlug(slug: string, options?: any): Promise<ItemGenre>;

    /**
     * 
     * @summary Get all genres
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApiInterface
     */
    metaControllerGenres(options?: any): Promise<Array<ItemGenre>>;

    /**
     * 
     * @summary Get all partners
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApiInterface
     */
    metaControllerPartners(options?: any): Promise<Array<ItemPartner>>;

}

/**
 * MetaApi - object-oriented interface
 * @export
 * @class MetaApi
 * @extends {BaseAPI}
 */
export class MetaApi extends BaseAPI implements MetaApiInterface {
    /**
     * 
     * @summary Get artist by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public metaControllerArtistById(id: string, options?: any) {
        return MetaApiFp(this.configuration).metaControllerArtistById(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get artist by slug
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public metaControllerArtistBySlug(slug: string, options?: any) {
        return MetaApiFp(this.configuration).metaControllerArtistBySlug(slug, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all artists
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public metaControllerArtists(options?: any) {
        return MetaApiFp(this.configuration).metaControllerArtists(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public metaControllerCategories(options?: any) {
        return MetaApiFp(this.configuration).metaControllerCategories(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get category by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public metaControllerCategoryById(id: string, options?: any) {
        return MetaApiFp(this.configuration).metaControllerCategoryById(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get category by slug
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public metaControllerCategoryBySlug(slug: string, options?: any) {
        return MetaApiFp(this.configuration).metaControllerCategoryBySlug(slug, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get condition by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public metaControllerConditionById(id: string, options?: any) {
        return MetaApiFp(this.configuration).metaControllerConditionById(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all conditions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public metaControllerConditions(options?: any) {
        return MetaApiFp(this.configuration).metaControllerConditions(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get genre by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public metaControllerGenreById(id: string, options?: any) {
        return MetaApiFp(this.configuration).metaControllerGenreById(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get genre by slug
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public metaControllerGenreBySlug(slug: string, options?: any) {
        return MetaApiFp(this.configuration).metaControllerGenreBySlug(slug, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all genres
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public metaControllerGenres(options?: any) {
        return MetaApiFp(this.configuration).metaControllerGenres(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all partners
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetaApi
     */
    public metaControllerPartners(options?: any) {
        return MetaApiFp(this.configuration).metaControllerPartners(options)(this.fetch, this.basePath);
    }

}
/**
 * OfferApi - fetch parameter creator
 * @export
 */
export const OfferApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Accept an offer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerAcceptOffer(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling offerControllerAcceptOffer.');
            }
            const localVarPath = `/v1/offer/{id}/accept`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if offers can be made
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerCheckOffersPossible(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling offerControllerCheckOffersPossible.');
            }
            const localVarPath = `/v1/offer/items/{id}/offers-possible`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Counter an offer
         * @param {SubmitOfferDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerCounterOffer(body: SubmitOfferDto, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling offerControllerCounterOffer.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling offerControllerCounterOffer.');
            }
            const localVarPath = `/v1/offer/{id}/counter`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'SubmitOfferDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Offers
         * @param {string} id 
         * @param {boolean} [include_expired] Track Entity View
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerGetAllOffers(id: string, include_expired?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling offerControllerGetAllOffers.');
            }
            const localVarPath = `/v1/offer/items/{id}/all-offers`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            if (include_expired !== undefined) {
                localVarQueryParameter['include_expired'] = include_expired;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Current Offer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerGetCurrentOffer(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling offerControllerGetCurrentOffer.');
            }
            const localVarPath = `/v1/offer/items/{id}/current-offer`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Items in transcation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerGetItemsInTransaction(options: any = {}): FetchArgs {
            const localVarPath = `/v1/offer/items-in-transactions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Latest Offer per Item
         * @param {boolean} [include_expired] Track Entity View
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerGetOwnerLatestOfferPerItem(include_expired?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/v1/offer/owner-latest-offers`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            if (include_expired !== undefined) {
                localVarQueryParameter['include_expired'] = include_expired;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Transaction Shipping Label
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerGetTransactionShippingLabel(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling offerControllerGetTransactionShippingLabel.');
            }
            const localVarPath = `/v1/offer/transaction/{id}/label`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Latest Offer per Item
         * @param {boolean} [include_expired] Track Entity View
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerGetUserLatestOfferPerItem(include_expired?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/v1/offer/user-latest-offers`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            if (include_expired !== undefined) {
                localVarQueryParameter['include_expired'] = include_expired;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark Offers read
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerMarkRead(options: any = {}): FetchArgs {
            const localVarPath = `/v1/offer/mark-read`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reject an offer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerRejectOffer(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling offerControllerRejectOffer.');
            }
            const localVarPath = `/v1/offer/{id}/reject`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit an offer
         * @param {SubmitOfferDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerSubmitOffer(body: SubmitOfferDto, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling offerControllerSubmitOffer.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling offerControllerSubmitOffer.');
            }
            const localVarPath = `/v1/offer/items/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'SubmitOfferDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferApi - functional programming interface
 * @export
 */
export const OfferApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Accept an offer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerAcceptOffer(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Offer> {
            const localVarFetchArgs = OfferApiFetchParamCreator(configuration).offerControllerAcceptOffer(id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Check if offers can be made
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerCheckOffersPossible(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OffersPossibleDto> {
            const localVarFetchArgs = OfferApiFetchParamCreator(configuration).offerControllerCheckOffersPossible(id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Counter an offer
         * @param {SubmitOfferDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerCounterOffer(body: SubmitOfferDto, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Offer> {
            const localVarFetchArgs = OfferApiFetchParamCreator(configuration).offerControllerCounterOffer(body, id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get All Offers
         * @param {string} id 
         * @param {boolean} [include_expired] Track Entity View
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerGetAllOffers(id: string, include_expired?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Offer>> {
            const localVarFetchArgs = OfferApiFetchParamCreator(configuration).offerControllerGetAllOffers(id, include_expired, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Current Offer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerGetCurrentOffer(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Offer> {
            const localVarFetchArgs = OfferApiFetchParamCreator(configuration).offerControllerGetCurrentOffer(id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Items in transcation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerGetItemsInTransaction(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Item>> {
            const localVarFetchArgs = OfferApiFetchParamCreator(configuration).offerControllerGetItemsInTransaction(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Latest Offer per Item
         * @param {boolean} [include_expired] Track Entity View
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerGetOwnerLatestOfferPerItem(include_expired?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LatestOfferDto>> {
            const localVarFetchArgs = OfferApiFetchParamCreator(configuration).offerControllerGetOwnerLatestOfferPerItem(include_expired, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Transaction Shipping Label
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerGetTransactionShippingLabel(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BufferDto> {
            const localVarFetchArgs = OfferApiFetchParamCreator(configuration).offerControllerGetTransactionShippingLabel(id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Latest Offer per Item
         * @param {boolean} [include_expired] Track Entity View
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerGetUserLatestOfferPerItem(include_expired?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LatestOfferDto>> {
            const localVarFetchArgs = OfferApiFetchParamCreator(configuration).offerControllerGetUserLatestOfferPerItem(include_expired, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Mark Offers read
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerMarkRead(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StatusDto> {
            const localVarFetchArgs = OfferApiFetchParamCreator(configuration).offerControllerMarkRead(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Reject an offer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerRejectOffer(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Offer> {
            const localVarFetchArgs = OfferApiFetchParamCreator(configuration).offerControllerRejectOffer(id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Submit an offer
         * @param {SubmitOfferDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerSubmitOffer(body: SubmitOfferDto, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Offer> {
            const localVarFetchArgs = OfferApiFetchParamCreator(configuration).offerControllerSubmitOffer(body, id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OfferApi - factory interface
 * @export
 */
export const OfferApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Accept an offer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerAcceptOffer(id: string, options?: any) {
            return OfferApiFp(configuration).offerControllerAcceptOffer(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Check if offers can be made
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerCheckOffersPossible(id: string, options?: any) {
            return OfferApiFp(configuration).offerControllerCheckOffersPossible(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Counter an offer
         * @param {SubmitOfferDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerCounterOffer(body: SubmitOfferDto, id: string, options?: any) {
            return OfferApiFp(configuration).offerControllerCounterOffer(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get All Offers
         * @param {string} id 
         * @param {boolean} [include_expired] Track Entity View
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerGetAllOffers(id: string, include_expired?: boolean, options?: any) {
            return OfferApiFp(configuration).offerControllerGetAllOffers(id, include_expired, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Current Offer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerGetCurrentOffer(id: string, options?: any) {
            return OfferApiFp(configuration).offerControllerGetCurrentOffer(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Items in transcation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerGetItemsInTransaction(options?: any) {
            return OfferApiFp(configuration).offerControllerGetItemsInTransaction(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Latest Offer per Item
         * @param {boolean} [include_expired] Track Entity View
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerGetOwnerLatestOfferPerItem(include_expired?: boolean, options?: any) {
            return OfferApiFp(configuration).offerControllerGetOwnerLatestOfferPerItem(include_expired, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Transaction Shipping Label
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerGetTransactionShippingLabel(id: string, options?: any) {
            return OfferApiFp(configuration).offerControllerGetTransactionShippingLabel(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Latest Offer per Item
         * @param {boolean} [include_expired] Track Entity View
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerGetUserLatestOfferPerItem(include_expired?: boolean, options?: any) {
            return OfferApiFp(configuration).offerControllerGetUserLatestOfferPerItem(include_expired, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Mark Offers read
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerMarkRead(options?: any) {
            return OfferApiFp(configuration).offerControllerMarkRead(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Reject an offer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerRejectOffer(id: string, options?: any) {
            return OfferApiFp(configuration).offerControllerRejectOffer(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Submit an offer
         * @param {SubmitOfferDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerControllerSubmitOffer(body: SubmitOfferDto, id: string, options?: any) {
            return OfferApiFp(configuration).offerControllerSubmitOffer(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * OfferApi - interface
 * @export
 * @interface OfferApi
 */
export interface OfferApiInterface {
    /**
     * 
     * @summary Accept an offer
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApiInterface
     */
    offerControllerAcceptOffer(id: string, options?: any): Promise<Offer>;

    /**
     * 
     * @summary Check if offers can be made
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApiInterface
     */
    offerControllerCheckOffersPossible(id: string, options?: any): Promise<OffersPossibleDto>;

    /**
     * 
     * @summary Counter an offer
     * @param {SubmitOfferDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApiInterface
     */
    offerControllerCounterOffer(body: SubmitOfferDto, id: string, options?: any): Promise<Offer>;

    /**
     * 
     * @summary Get All Offers
     * @param {string} id 
     * @param {boolean} [include_expired] Track Entity View
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApiInterface
     */
    offerControllerGetAllOffers(id: string, include_expired?: boolean, options?: any): Promise<Array<Offer>>;

    /**
     * 
     * @summary Get Current Offer
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApiInterface
     */
    offerControllerGetCurrentOffer(id: string, options?: any): Promise<Offer>;

    /**
     * 
     * @summary Get Items in transcation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApiInterface
     */
    offerControllerGetItemsInTransaction(options?: any): Promise<Array<Item>>;

    /**
     * 
     * @summary Get Latest Offer per Item
     * @param {boolean} [include_expired] Track Entity View
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApiInterface
     */
    offerControllerGetOwnerLatestOfferPerItem(include_expired?: boolean, options?: any): Promise<Array<LatestOfferDto>>;

    /**
     * 
     * @summary Get Transaction Shipping Label
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApiInterface
     */
    offerControllerGetTransactionShippingLabel(id: string, options?: any): Promise<BufferDto>;

    /**
     * 
     * @summary Get Latest Offer per Item
     * @param {boolean} [include_expired] Track Entity View
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApiInterface
     */
    offerControllerGetUserLatestOfferPerItem(include_expired?: boolean, options?: any): Promise<Array<LatestOfferDto>>;

    /**
     * 
     * @summary Mark Offers read
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApiInterface
     */
    offerControllerMarkRead(options?: any): Promise<StatusDto>;

    /**
     * 
     * @summary Reject an offer
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApiInterface
     */
    offerControllerRejectOffer(id: string, options?: any): Promise<Offer>;

    /**
     * 
     * @summary Submit an offer
     * @param {SubmitOfferDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApiInterface
     */
    offerControllerSubmitOffer(body: SubmitOfferDto, id: string, options?: any): Promise<Offer>;

}

/**
 * OfferApi - object-oriented interface
 * @export
 * @class OfferApi
 * @extends {BaseAPI}
 */
export class OfferApi extends BaseAPI implements OfferApiInterface {
    /**
     * 
     * @summary Accept an offer
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerControllerAcceptOffer(id: string, options?: any) {
        return OfferApiFp(this.configuration).offerControllerAcceptOffer(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Check if offers can be made
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerControllerCheckOffersPossible(id: string, options?: any) {
        return OfferApiFp(this.configuration).offerControllerCheckOffersPossible(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Counter an offer
     * @param {SubmitOfferDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerControllerCounterOffer(body: SubmitOfferDto, id: string, options?: any) {
        return OfferApiFp(this.configuration).offerControllerCounterOffer(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get All Offers
     * @param {string} id 
     * @param {boolean} [include_expired] Track Entity View
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerControllerGetAllOffers(id: string, include_expired?: boolean, options?: any) {
        return OfferApiFp(this.configuration).offerControllerGetAllOffers(id, include_expired, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Current Offer
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerControllerGetCurrentOffer(id: string, options?: any) {
        return OfferApiFp(this.configuration).offerControllerGetCurrentOffer(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Items in transcation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerControllerGetItemsInTransaction(options?: any) {
        return OfferApiFp(this.configuration).offerControllerGetItemsInTransaction(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Latest Offer per Item
     * @param {boolean} [include_expired] Track Entity View
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerControllerGetOwnerLatestOfferPerItem(include_expired?: boolean, options?: any) {
        return OfferApiFp(this.configuration).offerControllerGetOwnerLatestOfferPerItem(include_expired, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Transaction Shipping Label
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerControllerGetTransactionShippingLabel(id: string, options?: any) {
        return OfferApiFp(this.configuration).offerControllerGetTransactionShippingLabel(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Latest Offer per Item
     * @param {boolean} [include_expired] Track Entity View
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerControllerGetUserLatestOfferPerItem(include_expired?: boolean, options?: any) {
        return OfferApiFp(this.configuration).offerControllerGetUserLatestOfferPerItem(include_expired, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Mark Offers read
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerControllerMarkRead(options?: any) {
        return OfferApiFp(this.configuration).offerControllerMarkRead(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Reject an offer
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerControllerRejectOffer(id: string, options?: any) {
        return OfferApiFp(this.configuration).offerControllerRejectOffer(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Submit an offer
     * @param {SubmitOfferDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerControllerSubmitOffer(body: SubmitOfferDto, id: string, options?: any) {
        return OfferApiFp(this.configuration).offerControllerSubmitOffer(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * PaymentApi - fetch parameter creator
 * @export
 */
export const PaymentApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add bank account with setup id
         * @param {SetupIntentDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerAddBank(body: SetupIntentDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling paymentControllerAddBank.');
            }
            const localVarPath = `/v1/payment/setup-bank-account`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'SetupIntentDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add card with setup id
         * @param {SetupIntentDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerAddCard(body: SetupIntentDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling paymentControllerAddCard.');
            }
            const localVarPath = `/v1/payment/setup-card`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'SetupIntentDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Connected Account Setup Url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerGetConncetedAccountLink(options: any = {}): FetchArgs {
            const localVarPath = `/v1/payment/setup-connected-account`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get cards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerGetPaymentMethods(options: any = {}): FetchArgs {
            const localVarPath = `/v1/payment/methods`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Redirect to Connected Account Setup Url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerRedirectConncetedAccountLink(options: any = {}): FetchArgs {
            const localVarPath = `/v1/payment/setup-connected-account/redirect`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get client secret for bank account setup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerSetupBank(options: any = {}): FetchArgs {
            const localVarPath = `/v1/payment/setup-bank-account`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get client secret for credit card setup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerSetupCard(options: any = {}): FetchArgs {
            const localVarPath = `/v1/payment/setup-card`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add bank account with setup id
         * @param {SetupIntentDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerAddBank(body: SetupIntentDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserBankAccount> {
            const localVarFetchArgs = PaymentApiFetchParamCreator(configuration).paymentControllerAddBank(body, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add card with setup id
         * @param {SetupIntentDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerAddCard(body: SetupIntentDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserCard> {
            const localVarFetchArgs = PaymentApiFetchParamCreator(configuration).paymentControllerAddCard(body, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Connected Account Setup Url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerGetConncetedAccountLink(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UrlDto> {
            const localVarFetchArgs = PaymentApiFetchParamCreator(configuration).paymentControllerGetConncetedAccountLink(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get cards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerGetPaymentMethods(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentAccountDto> {
            const localVarFetchArgs = PaymentApiFetchParamCreator(configuration).paymentControllerGetPaymentMethods(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Redirect to Connected Account Setup Url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerRedirectConncetedAccountLink(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PaymentApiFetchParamCreator(configuration).paymentControllerRedirectConncetedAccountLink(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get client secret for bank account setup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerSetupBank(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClientSecretDto> {
            const localVarFetchArgs = PaymentApiFetchParamCreator(configuration).paymentControllerSetupBank(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get client secret for credit card setup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerSetupCard(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClientSecretDto> {
            const localVarFetchArgs = PaymentApiFetchParamCreator(configuration).paymentControllerSetupCard(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Add bank account with setup id
         * @param {SetupIntentDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerAddBank(body: SetupIntentDto, options?: any) {
            return PaymentApiFp(configuration).paymentControllerAddBank(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add card with setup id
         * @param {SetupIntentDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerAddCard(body: SetupIntentDto, options?: any) {
            return PaymentApiFp(configuration).paymentControllerAddCard(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Connected Account Setup Url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerGetConncetedAccountLink(options?: any) {
            return PaymentApiFp(configuration).paymentControllerGetConncetedAccountLink(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get cards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerGetPaymentMethods(options?: any) {
            return PaymentApiFp(configuration).paymentControllerGetPaymentMethods(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Redirect to Connected Account Setup Url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerRedirectConncetedAccountLink(options?: any) {
            return PaymentApiFp(configuration).paymentControllerRedirectConncetedAccountLink(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get client secret for bank account setup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerSetupBank(options?: any) {
            return PaymentApiFp(configuration).paymentControllerSetupBank(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get client secret for credit card setup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentControllerSetupCard(options?: any) {
            return PaymentApiFp(configuration).paymentControllerSetupCard(options)(fetch, basePath);
        },
    };
};

/**
 * PaymentApi - interface
 * @export
 * @interface PaymentApi
 */
export interface PaymentApiInterface {
    /**
     * 
     * @summary Add bank account with setup id
     * @param {SetupIntentDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApiInterface
     */
    paymentControllerAddBank(body: SetupIntentDto, options?: any): Promise<UserBankAccount>;

    /**
     * 
     * @summary Add card with setup id
     * @param {SetupIntentDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApiInterface
     */
    paymentControllerAddCard(body: SetupIntentDto, options?: any): Promise<UserCard>;

    /**
     * 
     * @summary Get Connected Account Setup Url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApiInterface
     */
    paymentControllerGetConncetedAccountLink(options?: any): Promise<UrlDto>;

    /**
     * 
     * @summary Get cards
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApiInterface
     */
    paymentControllerGetPaymentMethods(options?: any): Promise<PaymentAccountDto>;

    /**
     * 
     * @summary Redirect to Connected Account Setup Url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApiInterface
     */
    paymentControllerRedirectConncetedAccountLink(options?: any): Promise<{}>;

    /**
     * 
     * @summary Get client secret for bank account setup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApiInterface
     */
    paymentControllerSetupBank(options?: any): Promise<ClientSecretDto>;

    /**
     * 
     * @summary Get client secret for credit card setup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApiInterface
     */
    paymentControllerSetupCard(options?: any): Promise<ClientSecretDto>;

}

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI implements PaymentApiInterface {
    /**
     * 
     * @summary Add bank account with setup id
     * @param {SetupIntentDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentControllerAddBank(body: SetupIntentDto, options?: any) {
        return PaymentApiFp(this.configuration).paymentControllerAddBank(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add card with setup id
     * @param {SetupIntentDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentControllerAddCard(body: SetupIntentDto, options?: any) {
        return PaymentApiFp(this.configuration).paymentControllerAddCard(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Connected Account Setup Url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentControllerGetConncetedAccountLink(options?: any) {
        return PaymentApiFp(this.configuration).paymentControllerGetConncetedAccountLink(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get cards
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentControllerGetPaymentMethods(options?: any) {
        return PaymentApiFp(this.configuration).paymentControllerGetPaymentMethods(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Redirect to Connected Account Setup Url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentControllerRedirectConncetedAccountLink(options?: any) {
        return PaymentApiFp(this.configuration).paymentControllerRedirectConncetedAccountLink(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get client secret for bank account setup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentControllerSetupBank(options?: any) {
        return PaymentApiFp(this.configuration).paymentControllerSetupBank(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get client secret for credit card setup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentControllerSetupCard(options?: any) {
        return PaymentApiFp(this.configuration).paymentControllerSetupCard(options)(this.fetch, this.basePath);
    }

}
/**
 * SDKApi - fetch parameter creator
 * @export
 */
export const SDKApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get items
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sdkControllerGetItems(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling sdkControllerGetItems.');
            }
            const localVarPath = `/v1/sdk/{id}/items`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SDKApi - functional programming interface
 * @export
 */
export const SDKApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get items
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sdkControllerGetItems(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Item>> {
            const localVarFetchArgs = SDKApiFetchParamCreator(configuration).sdkControllerGetItems(id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SDKApi - factory interface
 * @export
 */
export const SDKApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get items
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sdkControllerGetItems(id: string, options?: any) {
            return SDKApiFp(configuration).sdkControllerGetItems(id, options)(fetch, basePath);
        },
    };
};

/**
 * SDKApi - interface
 * @export
 * @interface SDKApi
 */
export interface SDKApiInterface {
    /**
     * 
     * @summary Get items
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SDKApiInterface
     */
    sdkControllerGetItems(id: string, options?: any): Promise<Array<Item>>;

}

/**
 * SDKApi - object-oriented interface
 * @export
 * @class SDKApi
 * @extends {BaseAPI}
 */
export class SDKApi extends BaseAPI implements SDKApiInterface {
    /**
     * 
     * @summary Get items
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SDKApi
     */
    public sdkControllerGetItems(id: string, options?: any) {
        return SDKApiFp(this.configuration).sdkControllerGetItems(id, options)(this.fetch, this.basePath);
    }

}
/**
 * SearchApi - fetch parameter creator
 * @export
 */
export const SearchApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search
         * @param {number} [offset] Result cursor (amount of results to skip)
         * @param {number} [limit] Limit results
         * @param {string} [q] The search string
         * @param {Array<string>} [artist] The artist slug(s)
         * @param {Array<string>} [genre] The genre slug(s)
         * @param {Array<string>} [category] The category slug(s)
         * @param {Array<string>} [sub_category] The sub category slug(s)
         * @param {Array<string>} [condition] The condition name(s)
         * @param {Array<string>} [partner] The partner name(s)
         * @param {Array<string>} [collection] The collection slug(s)
         * @param {boolean} [has_active_auction] Filter for active auctions
         * @param {Array<string>} [offer_type] 
         * @param {boolean} [autographed] Filter for autographed items
         * @param {string} [sort_by] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchControllerSearch(offset?: number, limit?: number, q?: string, artist?: Array<string>, genre?: Array<string>, category?: Array<string>, sub_category?: Array<string>, condition?: Array<string>, partner?: Array<string>, collection?: Array<string>, has_active_auction?: boolean, offer_type?: Array<string>, autographed?: boolean, sort_by?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (artist) {
                localVarQueryParameter['artist'] = artist;
            }

            if (genre) {
                localVarQueryParameter['genre'] = genre;
            }

            if (category) {
                localVarQueryParameter['category'] = category;
            }

            if (sub_category) {
                localVarQueryParameter['sub_category'] = sub_category;
            }

            if (condition) {
                localVarQueryParameter['condition'] = condition;
            }

            if (partner) {
                localVarQueryParameter['partner'] = partner;
            }

            if (collection) {
                localVarQueryParameter['collection'] = collection;
            }

            if (has_active_auction !== undefined) {
                localVarQueryParameter['has_active_auction'] = has_active_auction;
            }

            if (offer_type) {
                localVarQueryParameter['offer_type'] = offer_type;
            }

            if (autographed !== undefined) {
                localVarQueryParameter['autographed'] = autographed;
            }

            if (sort_by !== undefined) {
                localVarQueryParameter['sort_by'] = sort_by;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search
         * @param {number} [offset] Result cursor (amount of results to skip)
         * @param {number} [limit] Limit results
         * @param {string} [q] The search string
         * @param {Array<string>} [artist] The artist slug(s)
         * @param {Array<string>} [genre] The genre slug(s)
         * @param {Array<string>} [category] The category slug(s)
         * @param {Array<string>} [sub_category] The sub category slug(s)
         * @param {Array<string>} [condition] The condition name(s)
         * @param {Array<string>} [partner] The partner name(s)
         * @param {Array<string>} [collection] The collection slug(s)
         * @param {boolean} [has_active_auction] Filter for active auctions
         * @param {Array<string>} [offer_type] 
         * @param {boolean} [autographed] Filter for autographed items
         * @param {string} [sort_by] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchControllerSearch(offset?: number, limit?: number, q?: string, artist?: Array<string>, genre?: Array<string>, category?: Array<string>, sub_category?: Array<string>, condition?: Array<string>, partner?: Array<string>, collection?: Array<string>, has_active_auction?: boolean, offer_type?: Array<string>, autographed?: boolean, sort_by?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
            const localVarFetchArgs = SearchApiFetchParamCreator(configuration).searchControllerSearch(offset, limit, q, artist, genre, category, sub_category, condition, partner, collection, has_active_auction, offer_type, autographed, sort_by, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Search
         * @param {number} [offset] Result cursor (amount of results to skip)
         * @param {number} [limit] Limit results
         * @param {string} [q] The search string
         * @param {Array<string>} [artist] The artist slug(s)
         * @param {Array<string>} [genre] The genre slug(s)
         * @param {Array<string>} [category] The category slug(s)
         * @param {Array<string>} [sub_category] The sub category slug(s)
         * @param {Array<string>} [condition] The condition name(s)
         * @param {Array<string>} [partner] The partner name(s)
         * @param {Array<string>} [collection] The collection slug(s)
         * @param {boolean} [has_active_auction] Filter for active auctions
         * @param {Array<string>} [offer_type] 
         * @param {boolean} [autographed] Filter for autographed items
         * @param {string} [sort_by] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchControllerSearch(offset?: number, limit?: number, q?: string, artist?: Array<string>, genre?: Array<string>, category?: Array<string>, sub_category?: Array<string>, condition?: Array<string>, partner?: Array<string>, collection?: Array<string>, has_active_auction?: boolean, offer_type?: Array<string>, autographed?: boolean, sort_by?: string, options?: any) {
            return SearchApiFp(configuration).searchControllerSearch(offset, limit, q, artist, genre, category, sub_category, condition, partner, collection, has_active_auction, offer_type, autographed, sort_by, options)(fetch, basePath);
        },
    };
};

/**
 * SearchApi - interface
 * @export
 * @interface SearchApi
 */
export interface SearchApiInterface {
    /**
     * 
     * @summary Search
     * @param {number} [offset] Result cursor (amount of results to skip)
     * @param {number} [limit] Limit results
     * @param {string} [q] The search string
     * @param {Array<string>} [artist] The artist slug(s)
     * @param {Array<string>} [genre] The genre slug(s)
     * @param {Array<string>} [category] The category slug(s)
     * @param {Array<string>} [sub_category] The sub category slug(s)
     * @param {Array<string>} [condition] The condition name(s)
     * @param {Array<string>} [partner] The partner name(s)
     * @param {Array<string>} [collection] The collection slug(s)
     * @param {boolean} [has_active_auction] Filter for active auctions
     * @param {Array<string>} [offer_type] 
     * @param {boolean} [autographed] Filter for autographed items
     * @param {string} [sort_by] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApiInterface
     */
    searchControllerSearch(offset?: number, limit?: number, q?: string, artist?: Array<string>, genre?: Array<string>, category?: Array<string>, sub_category?: Array<string>, condition?: Array<string>, partner?: Array<string>, collection?: Array<string>, has_active_auction?: boolean, offer_type?: Array<string>, autographed?: boolean, sort_by?: string, options?: any): Promise<InlineResponse200>;

}

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI implements SearchApiInterface {
    /**
     * 
     * @summary Search
     * @param {number} [offset] Result cursor (amount of results to skip)
     * @param {number} [limit] Limit results
     * @param {string} [q] The search string
     * @param {Array<string>} [artist] The artist slug(s)
     * @param {Array<string>} [genre] The genre slug(s)
     * @param {Array<string>} [category] The category slug(s)
     * @param {Array<string>} [sub_category] The sub category slug(s)
     * @param {Array<string>} [condition] The condition name(s)
     * @param {Array<string>} [partner] The partner name(s)
     * @param {Array<string>} [collection] The collection slug(s)
     * @param {boolean} [has_active_auction] Filter for active auctions
     * @param {Array<string>} [offer_type] 
     * @param {boolean} [autographed] Filter for autographed items
     * @param {string} [sort_by] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchControllerSearch(offset?: number, limit?: number, q?: string, artist?: Array<string>, genre?: Array<string>, category?: Array<string>, sub_category?: Array<string>, condition?: Array<string>, partner?: Array<string>, collection?: Array<string>, has_active_auction?: boolean, offer_type?: Array<string>, autographed?: boolean, sort_by?: string, options?: any) {
        return SearchApiFp(this.configuration).searchControllerSearch(offset, limit, q, artist, genre, category, sub_category, condition, partner, collection, has_active_auction, offer_type, autographed, sort_by, options)(this.fetch, this.basePath);
    }

}
/**
 * UserApi - fetch parameter creator
 * @export
 */
export const UserApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a favorite item
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerAddToFavorites(itemId: string, options: any = {}): FetchArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling userControllerAddToFavorites.');
            }
            const localVarPath = `/v1/user/favorites/{itemId}`
                .replace(`{${'itemId'}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get alerts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerAlerts(options: any = {}): FetchArgs {
            const localVarPath = `/v1/user/alerts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Clear alerts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerClearAlerts(options: any = {}): FetchArgs {
            const localVarPath = `/v1/user/alerts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create address
         * @param {AddressDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateAddress(body: AddressDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling userControllerCreateAddress.');
            }
            const localVarPath = `/v1/user/addresses`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'AddressDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete address
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDeleteAddress(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling userControllerDeleteAddress.');
            }
            const localVarPath = `/v1/user/addresses/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete profile image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDeleteProfileImage(options: any = {}): FetchArgs {
            const localVarPath = `/v1/user/me/profile-image`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get favorite items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFavorites(options: any = {}): FetchArgs {
            const localVarPath = `/v1/user/favorites`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get addresses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetAddresses(options: any = {}): FetchArgs {
            const localVarPath = `/v1/user/addresses`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get credit limit
         * @param {string} [auction_id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetCreditLimit(auction_id?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/user/credit-limit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            if (auction_id !== undefined) {
                localVarQueryParameter['auction_id'] = auction_id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get credit limit history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetCreditLimitHistory(options: any = {}): FetchArgs {
            const localVarPath = `/v1/user/credit-limit/history`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get messaging subscriber code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetMessagingSubscriptionCode(options: any = {}): FetchArgs {
            const localVarPath = `/v1/user/subscribe-messaging/code`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerMe(options: any = {}): FetchArgs {
            const localVarPath = `/v1/user/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read alerts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerReadAlerts(options: any = {}): FetchArgs {
            const localVarPath = `/v1/user/alerts/read`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a favorite item
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerRemoveFromFavorites(itemId: string, options: any = {}): FetchArgs {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling userControllerRemoveFromFavorites.');
            }
            const localVarPath = `/v1/user/favorites/{itemId}`
                .replace(`{${'itemId'}}`, encodeURIComponent(String(itemId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Subscribe to newsletter
         * @param {NewsletterDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerSubscribeNewsletter(body: NewsletterDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling userControllerSubscribeNewsletter.');
            }
            const localVarPath = `/v1/user/newsletter/subscribe`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'NewsletterDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update address
         * @param {AddressDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateAddress(body: AddressDto, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling userControllerUpdateAddress.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling userControllerUpdateAddress.');
            }
            const localVarPath = `/v1/user/addresses/{id}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'AddressDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update current user
         * @param {UpdateUserDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateMe(body: UpdateUserDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling userControllerUpdateMe.');
            }
            const localVarPath = `/v1/user/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            
            localVarRequestOptions['credentials'] = 'include';

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>'UpdateUserDto' !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || '');

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update profile image
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUploadProfileImage(file?: Blob, options: any = {}): FetchArgs {
            const localVarPath = `/v1/user/me/profile-image`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();


            
            localVarRequestOptions['credentials'] = 'include';

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a favorite item
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerAddToFavorites(itemId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Item>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userControllerAddToFavorites(itemId, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get alerts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerAlerts(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<UserAlert>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userControllerAlerts(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Clear alerts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerClearAlerts(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StatusDto> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userControllerClearAlerts(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create address
         * @param {AddressDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateAddress(body: AddressDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<UserAddress>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userControllerCreateAddress(body, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete address
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDeleteAddress(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<UserAddress>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userControllerDeleteAddress(id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete profile image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDeleteProfileImage(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<User> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userControllerDeleteProfileImage(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get favorite items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFavorites(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Item>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userControllerFavorites(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get addresses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetAddresses(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<UserAddress>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userControllerGetAddresses(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get credit limit
         * @param {string} [auction_id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetCreditLimit(auction_id?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CreditLimitDto> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userControllerGetCreditLimit(auction_id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get credit limit history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetCreditLimitHistory(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<UserCredit>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userControllerGetCreditLimitHistory(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get messaging subscriber code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetMessagingSubscriptionCode(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MessagingSubscriptionCodeResponseDto> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userControllerGetMessagingSubscriptionCode(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerMe(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<User> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userControllerMe(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Read alerts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerReadAlerts(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StatusDto> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userControllerReadAlerts(options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Remove a favorite item
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerRemoveFromFavorites(itemId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Item>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userControllerRemoveFromFavorites(itemId, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Subscribe to newsletter
         * @param {NewsletterDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerSubscribeNewsletter(body: NewsletterDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StatusDto> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userControllerSubscribeNewsletter(body, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update address
         * @param {AddressDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateAddress(body: AddressDto, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<UserAddress>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userControllerUpdateAddress(body, id, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update current user
         * @param {UpdateUserDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateMe(body: UpdateUserDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<User> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userControllerUpdateMe(body, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update profile image
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUploadProfileImage(file?: Blob, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<User> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).userControllerUploadProfileImage(file, options);
            return (fetch: FetchAPI = fetchImplementation, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.status === 204) {
                            return undefined;
                        }
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Add a favorite item
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerAddToFavorites(itemId: string, options?: any) {
            return UserApiFp(configuration).userControllerAddToFavorites(itemId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get alerts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerAlerts(options?: any) {
            return UserApiFp(configuration).userControllerAlerts(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Clear alerts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerClearAlerts(options?: any) {
            return UserApiFp(configuration).userControllerClearAlerts(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create address
         * @param {AddressDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateAddress(body: AddressDto, options?: any) {
            return UserApiFp(configuration).userControllerCreateAddress(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete address
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDeleteAddress(id: string, options?: any) {
            return UserApiFp(configuration).userControllerDeleteAddress(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete profile image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDeleteProfileImage(options?: any) {
            return UserApiFp(configuration).userControllerDeleteProfileImage(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get favorite items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFavorites(options?: any) {
            return UserApiFp(configuration).userControllerFavorites(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get addresses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetAddresses(options?: any) {
            return UserApiFp(configuration).userControllerGetAddresses(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get credit limit
         * @param {string} [auction_id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetCreditLimit(auction_id?: string, options?: any) {
            return UserApiFp(configuration).userControllerGetCreditLimit(auction_id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get credit limit history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetCreditLimitHistory(options?: any) {
            return UserApiFp(configuration).userControllerGetCreditLimitHistory(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get messaging subscriber code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetMessagingSubscriptionCode(options?: any) {
            return UserApiFp(configuration).userControllerGetMessagingSubscriptionCode(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerMe(options?: any) {
            return UserApiFp(configuration).userControllerMe(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Read alerts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerReadAlerts(options?: any) {
            return UserApiFp(configuration).userControllerReadAlerts(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Remove a favorite item
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerRemoveFromFavorites(itemId: string, options?: any) {
            return UserApiFp(configuration).userControllerRemoveFromFavorites(itemId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Subscribe to newsletter
         * @param {NewsletterDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerSubscribeNewsletter(body: NewsletterDto, options?: any) {
            return UserApiFp(configuration).userControllerSubscribeNewsletter(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update address
         * @param {AddressDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateAddress(body: AddressDto, id: string, options?: any) {
            return UserApiFp(configuration).userControllerUpdateAddress(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update current user
         * @param {UpdateUserDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateMe(body: UpdateUserDto, options?: any) {
            return UserApiFp(configuration).userControllerUpdateMe(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update profile image
         * @param {Blob} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUploadProfileImage(file?: Blob, options?: any) {
            return UserApiFp(configuration).userControllerUploadProfileImage(file, options)(fetch, basePath);
        },
    };
};

/**
 * UserApi - interface
 * @export
 * @interface UserApi
 */
export interface UserApiInterface {
    /**
     * 
     * @summary Add a favorite item
     * @param {string} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerAddToFavorites(itemId: string, options?: any): Promise<Array<Item>>;

    /**
     * 
     * @summary Get alerts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerAlerts(options?: any): Promise<Array<UserAlert>>;

    /**
     * 
     * @summary Clear alerts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerClearAlerts(options?: any): Promise<StatusDto>;

    /**
     * 
     * @summary Create address
     * @param {AddressDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerCreateAddress(body: AddressDto, options?: any): Promise<Array<UserAddress>>;

    /**
     * 
     * @summary Delete address
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerDeleteAddress(id: string, options?: any): Promise<Array<UserAddress>>;

    /**
     * 
     * @summary Delete profile image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerDeleteProfileImage(options?: any): Promise<User>;

    /**
     * 
     * @summary Get favorite items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerFavorites(options?: any): Promise<Array<Item>>;

    /**
     * 
     * @summary Get addresses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerGetAddresses(options?: any): Promise<Array<UserAddress>>;

    /**
     * 
     * @summary Get credit limit
     * @param {string} [auction_id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerGetCreditLimit(auction_id?: string, options?: any): Promise<CreditLimitDto>;

    /**
     * 
     * @summary Get credit limit history
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerGetCreditLimitHistory(options?: any): Promise<Array<UserCredit>>;

    /**
     * 
     * @summary Get messaging subscriber code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerGetMessagingSubscriptionCode(options?: any): Promise<MessagingSubscriptionCodeResponseDto>;

    /**
     * 
     * @summary Get current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerMe(options?: any): Promise<User>;

    /**
     * 
     * @summary Read alerts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerReadAlerts(options?: any): Promise<StatusDto>;

    /**
     * 
     * @summary Remove a favorite item
     * @param {string} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerRemoveFromFavorites(itemId: string, options?: any): Promise<Array<Item>>;

    /**
     * 
     * @summary Subscribe to newsletter
     * @param {NewsletterDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerSubscribeNewsletter(body: NewsletterDto, options?: any): Promise<StatusDto>;

    /**
     * 
     * @summary Update address
     * @param {AddressDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerUpdateAddress(body: AddressDto, id: string, options?: any): Promise<Array<UserAddress>>;

    /**
     * 
     * @summary Update current user
     * @param {UpdateUserDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerUpdateMe(body: UpdateUserDto, options?: any): Promise<User>;

    /**
     * 
     * @summary Update profile image
     * @param {Blob} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userControllerUploadProfileImage(file?: Blob, options?: any): Promise<User>;

}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI implements UserApiInterface {
    /**
     * 
     * @summary Add a favorite item
     * @param {string} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerAddToFavorites(itemId: string, options?: any) {
        return UserApiFp(this.configuration).userControllerAddToFavorites(itemId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get alerts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerAlerts(options?: any) {
        return UserApiFp(this.configuration).userControllerAlerts(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Clear alerts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerClearAlerts(options?: any) {
        return UserApiFp(this.configuration).userControllerClearAlerts(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create address
     * @param {AddressDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerCreateAddress(body: AddressDto, options?: any) {
        return UserApiFp(this.configuration).userControllerCreateAddress(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete address
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerDeleteAddress(id: string, options?: any) {
        return UserApiFp(this.configuration).userControllerDeleteAddress(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete profile image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerDeleteProfileImage(options?: any) {
        return UserApiFp(this.configuration).userControllerDeleteProfileImage(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get favorite items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerFavorites(options?: any) {
        return UserApiFp(this.configuration).userControllerFavorites(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get addresses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerGetAddresses(options?: any) {
        return UserApiFp(this.configuration).userControllerGetAddresses(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get credit limit
     * @param {string} [auction_id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerGetCreditLimit(auction_id?: string, options?: any) {
        return UserApiFp(this.configuration).userControllerGetCreditLimit(auction_id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get credit limit history
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerGetCreditLimitHistory(options?: any) {
        return UserApiFp(this.configuration).userControllerGetCreditLimitHistory(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get messaging subscriber code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerGetMessagingSubscriptionCode(options?: any) {
        return UserApiFp(this.configuration).userControllerGetMessagingSubscriptionCode(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerMe(options?: any) {
        return UserApiFp(this.configuration).userControllerMe(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Read alerts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerReadAlerts(options?: any) {
        return UserApiFp(this.configuration).userControllerReadAlerts(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Remove a favorite item
     * @param {string} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerRemoveFromFavorites(itemId: string, options?: any) {
        return UserApiFp(this.configuration).userControllerRemoveFromFavorites(itemId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Subscribe to newsletter
     * @param {NewsletterDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerSubscribeNewsletter(body: NewsletterDto, options?: any) {
        return UserApiFp(this.configuration).userControllerSubscribeNewsletter(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update address
     * @param {AddressDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerUpdateAddress(body: AddressDto, id: string, options?: any) {
        return UserApiFp(this.configuration).userControllerUpdateAddress(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update current user
     * @param {UpdateUserDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerUpdateMe(body: UpdateUserDto, options?: any) {
        return UserApiFp(this.configuration).userControllerUpdateMe(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update profile image
     * @param {Blob} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerUploadProfileImage(file?: Blob, options?: any) {
        return UserApiFp(this.configuration).userControllerUploadProfileImage(file, options)(this.fetch, this.basePath);
    }

}
